<template>
  <div v-if="bannerList.length > 0">
    <Title :imgName="'title_campaign.webp'" :width="'115'" :height="'20'" :alt="'キャンペーン'" />
    <div class="campaign">
      <img class="campaign_bg" :src="`${cdn}/img/top/img_campaign_bg.webp`" width="375" height="448" alt="">
      <div class="campaign_list">
        <div class="list" :style="bannerTransitionX()">
          <a class="list_banner" v-for="(banner, index) in bannerList" :key="index" :href="banner.url" :target="banner.isNewTab ? '_blank' : ''">
            <img :src="`/static/campaign/img/${banner.imgName}`" width="240" height="240" :alt="banner.alt">
          </a>
        </div>
        <button :class="['listButton listButton-prev', { 'listButton-disabled': nowBannerNo == 0 }]" @click="bannerPrev()">
          <img class="listButton_img" :src="`${cdn}/img/top/icon_arrow_left_white.webp`" width="7" height="11" alt="前へ">
        </button>
        <button :class="['listButton listButton-next', { 'listButton-disabled': nowBannerNo == bannerList.length - 1 }]" @click="bannerNext()">
          <img class="listButton_img" :src="`${cdn}/img/top/icon_arrow_right_white.webp`" width="7" height="11" alt="次へ">
        </button>
      </div>
      <div class="campaign_title">
        <img :src="`${cdn}/img/top/title_check_campaign.webp`" width="286" height="18" alt="おトクなキャンペーンをチェックしよう">
      </div>
      <DecoratedLink :routeName="'Campaign'" :imgName="'title_all_campaign.webp'" :width="'172'" :height="'18'" :alt="'すべてのキャンペーン'"/>
    </div>
  </div>
</template>

<style scoped>
.campaign {
  max-width: 375px;
  height: 448px;
  margin: -61px auto 30px;
  overflow: hidden;
}

.campaign_bg {
  position: absolute;
  top: 0;
  left: 0;
}

.campaign_list {
  z-index: 1;
  height: 240px;
  margin-top: 52px;
  margin-bottom: 8px;
  overflow: hidden;
}

.list {
  position: absolute;
  top: 0;
  left: 68px;
  z-index: 1;
  height: 240px;
  display: flex;
  flex-wrap: nowrap;
  transition: transform .3s;
  transform: translateX(var(--transition-x));
}

.list_banner {
  width: 240px;
  height: 240px;
  margin-right: 30px;
  text-align: center;
}

.listButton {
  position: absolute;
  top: calc(50% - 25px);
  z-index: 2;
  background-color: var(--col-main);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.listButton-disabled {
  background-color: #cbcbcb;
  pointer-events: none;
}

.listButton-disabled .listButton_img {
  opacity: .5;
}

.listButton-prev {
  left: 10px;
}

.listButton-next {
  right: 10px;
}

.campaign_title {
  margin-bottom: 21px;
  text-align: center;
}

.campaign_button {
  text-align: center;
  padding: 0 20px;
}
</style>

<script>
import Title from './Title'
import DecoratedLink from './DecoratedLink'
import { getList } from '../lib/listModules'
import { getServerDateString } from '../lib/dateModules'

const BANNER_INTERVAL_MSEC = 5000
export default {
  props:{
		isAutoBanner: { type: Boolean, default: false }
  },

  data: function() {
    return {
      cdn: CDN,
      nowBannerNo: 0,
      bannerList: [],
      timer: null
    }
  },

  mounted: async function() {
    this.setTimer()
    const bannerList = await getList('banner.json')
    this.bannerList = this.getFilterList(bannerList)
  },

  methods: {
    setTimer: function() {
      if (this.isAutoBanner) {
        clearInterval(this.timer)
        this.timer = setInterval(this.bannerNext, BANNER_INTERVAL_MSEC)
      }
    },

    bannerPrev: function() {
      const prevNo = this.nowBannerNo == 0 ? this.bannerList.length - 1 : this.nowBannerNo - 1
      this.bannerToID(prevNo)
    },

    bannerNext: function() {
      const nextNo = this.nowBannerNo == this.bannerList.length - 1 ? 0 : this.nowBannerNo + 1
      this.bannerToID(nextNo)
    },

    bannerToID: function(no) {
      if (0 <= no && no < this.bannerList.length) {
        this.nowBannerNo = no
        this.setTimer()
      }
    },

    bannerTransitionX: function() {
      return {
        '--transition-x': `${-270 * this.nowBannerNo}px`
      }
    },

    getFilterList: function(list) {
      if (list.length > 0) {
        const serverDate = getServerDateString(NOW_DATE)
        return list.filter(
          (banner) => (!banner.releaseDate || banner.releaseDate <= serverDate)
            && (!banner.endDate || serverDate <= banner.endDate)
        )
      } else {
        return list
      }
    }
  },

  components: {
    Title,
    DecoratedLink
  }
}
</script>
