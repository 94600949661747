<template>
  <div class="titleRow">
    <div class="title">
      <img class="title_bg" :src="`${cdn}/img/top/img_title.webp`" width="353" height="78" alt="">
      <img
        v-if="imgName != ''"
        class="title_text"
        :src="`${cdn}/img/top/${imgName}`"
        :width="width"
        :height="height"
        :alt="alt"
      >
      <span v-else class="title_alt">{{ alt }}</span>
    </div>
  </div>
</template>

<style scoped>
.titleRow {
  z-index: 1;
  padding: 0 20px;
  margin-bottom: 30px;
  text-align: center;
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.title_text {
  position: absolute;
  top: -12px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  margin: auto;
  padding: 0 50px;
  width: auto;
  height: 20px;
}

.title_alt {
  position: absolute;
  top: 17px;
  color: #ffffff;
  font-size: 20px;
}
</style>

<script>
export default {
  props:{
		imgName: { type: String, default: "" },
		width: { type: String, default: "" },
		height: { type: String, default: "" },
    alt: { type: String, default: "" }
  },

  data: function() {
    return {
      cdn: CDN
    }
  },

  mounted: async function() {
  },

  methods: {
  },

  components: {
  }
}
</script>
