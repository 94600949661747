<template>
  <div class="footerArea">
    <button class="toTop" @click="onClickToTop()">
      <img :src="`${cdn}/img/top/icon_arrow_up.webp`" width="11" height="7" alt="TOPへ">
    </button>

    <div class="footer">
      <div class="footer_top">
        <div class="linkRow">
          <router-link
            :class="['link', {'link-now': isNow('Index') || isNow('Index2')}]"
            :to="{ name: 'Index' }"
          >
            トップ
          </router-link>
        </div>
      </div>
      <div class="footer_pages">
        <div class="linkRow">
          <router-link
            :class="['link', {'link-now': isNow('Campaign') || isNow('CampaignDetail')}]"
            :to="{ name: 'Campaign' }"
          >
            キャンペーン
          </router-link>
        </div>
        <div class="linkRow">
          <router-link
            :class="['link', {'link-now': isNow('Feature')}]"
            :to="{ name: 'Feature' }"
          >
            アプリの特長
          </router-link>
          <div class="linkRow-child">
            <router-link
              :class="['link', {'link-now': isNow('Partners')}]"
              :to="{ name: 'Partners' }"
            >
              提携ポイント・交換レート
            </router-link>
          </div>
          <div class="linkRow-child">
            <router-link
              :class="['link', {'link-now': isNow('Brands')}]"
              :to="{ name: 'Brands' }"
            >
              銘柄ラインアップ
            </router-link>
          </div>
        </div>
        <div class="linkRow">
          <router-link
            :class="['link', {'link-now': isNow('Story')}]"
            :to="{ name: 'Story' }"
          >
            ストーリーとキャラクター
          </router-link>
          <div class="linkRow-child">
            <router-link
              :class="['link', {'link-now': isNow('Sodate')}]"
              :to="{ name: 'Sodate' }"
            >
              騎士ソダテ
            </router-link>
          </div>
          <div class="linkRow-child">
            <router-link
              :class="['link', {'link-now': isNow('Manabi')}]"
              :to="{ name: 'Manabi' }"
            >
              魔法使いマナビ
            </router-link>
          </div>
        </div>
        <div class="linkRow">
          <router-link
            :class="['link', {'link-now': isNow('Levelup')}]"
            :to="{ name: 'Levelup' }"
          >
            レベルアップとクラスチェンジ
          </router-link>
        </div>
        <div class="linkRow">
          <router-link
            :class="['link', {'link-now': isNow('Rule')}]"
            :to="{ name: 'Rule' }"
          >
            ゲームルール
          </router-link>
        </div>
        <div class="linkRow">
          <router-link
            :class="['link', {'link-now': isNow('News') || isNow('NewsDetail')}]"
            :to="{ name: 'News' }"
          >
            お知らせ
          </router-link>
        </div>
        <div class="linkRow">
          <a class="link" href="https://spm-help.stockpoint.jp/hc/ja" target="_blank">
            よくあるご質問
            <img class="link_icon" :src="`${cdn}/img/top/icon_tab.webp`" width="20" height="20" alt="新しいタブ">
          </a>
        </div>
      </div>
      <div class="footer_others">
        <div class="linkRow">
          <a class="link" href="https://spm-help.stockpoint.jp/hc/ja/requests/new" target="_blank">
            お問い合わせ
            <img class="link_icon" :src="`${cdn}/img/top/icon_tab.webp`" width="20" height="20" alt="新しいタブ">
          </a>
        </div>
        <div class="linkRow">
          <a class="link" :href="`${cdn}/static/terms.html`" target="_blank">
            会員規約・プライバシーポリシー
            <img class="link_icon" :src="`${cdn}/img/top/icon_tab.webp`" width="20" height="20" alt="新しいタブ">
          </a>
        </div>
        <div class="linkRow">
          <a class="link" href="https://stockpoint.co.jp" target="_blank">
            運営会社
            <img class="link_icon" :src="`${cdn}/img/top/icon_tab.webp`" width="20" height="20" alt="新しいタブ">
          </a>
        </div>
        <div class="linkRow-sns">
          <a class="link" href="https://twitter.com/SPMOFFICIAL2021" target="_blank">
            <img :src="`${cdn}/img/top/icon_x_black.webp`" width="31" height="32" alt="X">
          </a>
        </div>
      </div>
      <div class="footer_copyright">
        <div class="logo">
          <router-link :to="{ name: 'Index' }">
            <img :src="`${cdn}/img/top/logo_spm2.webp`" width="229" height="16" alt="STOCKPOINT for MUFG">
          </router-link>
        </div>
        <div class="caution">
          STOCKPOINT for MUFG（当サービスおよび当サイト）はSTOCKPOINT株式会社が運営しており、MUFG各社が運営するものではございません。
        </div>
        <div class="copyright">
          Copyright &copy; STOCKPOINT Inc.
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.footerArea {
  background-color: var(--col-grey);
  margin-top: 25px;
}

.toTop {
  position: absolute;
  top: -25px;
  right: 20px;
  z-index: 1;
  background-color: var(--col-main);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  box-shadow: 0 3px 6px rgba(0,0,0,.16);
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer {
  padding: 40px 20px 30px;
}

.footer_top,
.footer_pages,
.footer_others {
  border-bottom: 1px solid var(--col-black50);
}

.footer_pages,
.footer_others {
  padding-top: 30px;
}

.footer_others {
  padding-bottom: 15px;
}

.linkRow {
  margin-bottom: 30px;
}

.linkRow-sns {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer_others .linkRow {
  margin-bottom: 15px;
}

.linkRow-child {
  margin-top: 20px;
  margin-left: 10px;
}

.link {
  display: inline-flex;
  align-items: center;
  font-size: 15px;
  color: #4d494a;
}

.footer_others .link {
  opacity: .7;
}

.footer_others .linkRow-sns .link {
  opacity: 1;
  width: 32px;
  height: 32px;
  margin: 8px;
}

.link-now {
  color: var(--col-main);
}

.link_icon {
  margin-left: 8px;
}

.footer_copyright {
  padding-top: 35px;
}

.logo {
  text-align: center;
  margin-bottom: 25px;
}

.caution {
  font-size: 12px;
  line-height: 20px;
}

.copyright {
  font-size: 10px;
  opacity: .4;
  text-align: center;
}
</style>

<script>
export default {
  props:{
  },

  data: function() {
    return {
      cdn: CDN
    }
  },

  mounted: async function() {
  },

  methods: {
    isNow: function(name) {
      if (name == this.$route.name) {
        return true
      } else {
        return false
      }
    },

    onClickToTop: function() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  },

  components: {
  }
}
</script>
