<template>
  <div>
    <div class="header">
      <router-link :to="{ name: 'Index' }">
        <img :src="`${cdn}/img/top/logo_spm.webp`" width="209" height="15" alt="STOCKPOINT for MUFG">
      </router-link>
    </div>
    <div class="titleArea">
      <h1 class="titleArea_title">お知らせ</h1>
    </div>

    <div class="newsArea">
      <div>
        <select class="newsArea_nav" :style="navIconURL()" v-model="category">
          <option value="">すべてのお知らせ</option>
          <option value="お知らせ">お知らせ</option>
          <option value="プレスリリース">プレスリリース</option>
          <option value="重要なお知らせ">重要なお知らせ</option>
        </select>
      </div>
      <NewsList :newsList="getFilterList(newsList, category)" :isDispYear="true" :category="category" />
    </div>
  </div>
</template>

<style scoped>
.newsArea {
  padding: 0 20px;
}

.newsArea_nav {
  background: var(--col-grey) var(--icon-url) no-repeat top 50% right 15px / 18px;
}
</style>

<script>
import NewsList from '../components/NewsList'
import { getList } from '../lib/listModules'
import { getServerDateString } from '../lib/dateModules'

export default {
  props:{
  },

  data: function() {
    return {
      cdn: CDN,
      newsList: [],
      category: ''
    }
  },

  mounted: async function() {
    this.newsList = await getList('news.json')
  },

  methods: {
    getFilterList: function(list, category) {
      const serverDate = getServerDateString(NOW_DATE)
      const listExceptedFuture = list.filter(
        (news) => !news.releaseDate || news.releaseDate <= serverDate
      )
      if (category == '' || listExceptedFuture.length == 0) {
        return listExceptedFuture
      } else {
        return listExceptedFuture.filter(news => news.category == category)
      }
    },

    navIconURL: function() {
      return {
        '--icon-url': `url(${this.cdn}/img/top/icon_tri_down.webp)`
      }
    }
  },

  components: {
    NewsList
  }
}
</script>
