<template>
  <div class="appStore">
    <a class="appStore_icon" href="https://app.adjust.com/12z8f273?redirect=https%3A%2F%2Fapps.apple.com%2Fjp%2Fapp%2Fid1574893406%3Futm_source%3Dapp%26utm_medium%3Dapp%26utm_id%3Dspm_hp" target="_blank">
      <img :src="`${cdn}/img/top/icon_app_store.webp`" width="109" height="40" alt="App Storeからダウンロード">
    </a>
    <a class="appStore_icon" href="https://app.adjust.com/124zivr2?redirect=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Djp.stockpoint.spm" target="_blank">
      <img :src="`${cdn}/img/top/icon_google_play.webp`" width="137" height="41" alt="Google Playで手に入れよう">
    </a>
  </div>
</template>

<style scoped>
.appStore {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.appStore_icon:not(:last-child) {
  margin-right: 20px;
}
</style>

<script>
export default {
  props:{
  },

  data: function() {
    return {
      cdn: CDN
    }
  },

  mounted: async function() {
  },

  methods: {
  },

  components: {
  }
}
</script>
