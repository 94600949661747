<template>
  <div>
    <div class="header">
      <router-link :to="{ name: 'Index' }">
        <img :src="`${cdn}/img/top/logo_spm.webp`" width="209" height="15" alt="STOCKPOINT for MUFG">
      </router-link>
    </div>
    <div class="titleArea">
      <Breadcrumb />
      <h1 class="titleArea_title">ストーリーとキャラクター</h1>
      <div class="titleArea_subTitle">
        ポイント運用×RPGという新しい組み合わせ。<br>その世界観とキャラクターを紹介します。
      </div>
    </div>

    <div>
      <Title :alt="'CHARACTER'" />
      <div class="characterArea">
        <div class="character_bg">
          <img :src="`${cdn}/img/top/img_sodate_splash.webp`" width="375" height="310" alt="ソダテスプラッシュ">
        </div>
        <div class="character_inner">
          <img class="character_img" :src="`${cdn}/img/top/img_character.webp`" width="125" height="213" alt="ソダテ">
          <div class="character_article">
            <div class="character_description">
              由緒正しき<br>カブ家の若き騎士
            </div>
            <div class="character_name">
              ソダテ
            </div>
            <router-link class="character_link" :to="{ name: 'Sodate' }">
              ストーリー
            </router-link>
          </div>
        </div>
      </div>
      <div class="characterArea">
        <div class="character_bg">
          <img :src="`${cdn}/img/top/img_manabi_splash.webp`" width="375" height="310" alt="マナビスプラッシュ">
        </div>
        <div class="character_inner">
          <img class="character_img" :src="`${cdn}/img/top/img_manabi.webp`" width="125" height="221" alt="マナビ">
          <div class="character_article">
            <div class="character_description">
              市場の声が<br>聞こえる魔法使い
            </div>
            <div class="character_name">
              マナビ
            </div>
            <router-link class="character_link" :to="{ name: 'Manabi' }">
              ストーリー
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div id="switch-characters" class="article switchChara">
      <h2 class="article_heading2">
        キャラクターの切り替え
      </h2>
      <p class="article_text">
        STOCKPOINT for MUFGは、お気に入りのキャラクターを選んでプレイすることができます。
      </p>
      <div class="article_img">
        <img :src="`${cdn}/img/top/img_story_ss_01.webp`" width="236" height="477" alt="">
      </div>
      <p class="article_text">
        ①ホーム画面左上のボタンから「ステータス」画面へ
      </p>
      <div class="article_img">
        <img :src="`${cdn}/img/top/img_story_ss_02.webp`" width="224" height="475" alt="">
      </div>
      <p class="article_text">
        ②「チェンジ」のタップで、キャラクターの切り替えができます
      </p>
    </div>

    <div class="snsArea">
      <SNS />
    </div>
  </div>
</template>

<style scoped>
.characterArea {
  background-color: #f5f1ea;
  padding-bottom: 30px;
}

.character_bg {
  text-align: center;
}

.character_inner {
  margin: -30px auto 0;
  background-color: #ffffff;
  box-shadow: 0 3px 6px rgba(0,0,0,.16);
  border-radius: 20px;
  padding: 20px;
  max-width: 335px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  justify-content: flex-start;
}

.character_img {
  max-width: 125px;
}

.character_article {
  width: 100%;
}

.character_description {
  padding-top: 34px;
  text-align: center;
  padding-bottom: 18px;
}

.character_name {
  font-size: 28px;
  padding-bottom: 30px;
  text-align: center;
}

.character_link {
  display: flex;
  max-width: 160px;
  min-height: 50px;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  padding: 15px;
  color: #ffffff;
	background: linear-gradient(to bottom, #fc4249, var(--col-main));
  box-shadow: 0 2px 0 #a51216;
  margin: auto;
}

.switchChara {
  padding-top: 40px;
}

.snsArea {
  padding-top: 40px;
  padding-bottom: 30px;
}
</style>

<script>
import SNS from '../components/SNS'
import Title from '../components/Title'
import Title2 from '../components/Title2'
import Breadcrumb from '../components/Breadcrumb'

export default {
  props:{
  },

  data: function() {
    return {
      cdn: CDN
    }
  },

  mounted: async function() {
  },

  methods: {
  },

  components: {
    SNS,
    Title,
    Title2,
    Breadcrumb
  }
}
</script>
