<template>
  <div id="app" class="contents">
    <div v-if="$route.meta.noTemplate">
      <main>
        <router-view />
      </main>
    </div>
    <div v-else>
      <Header v-show="!isNavOpen" :onClickMenu="toggleNavOpen" />
      <Nav v-show="isNavOpen" :onClickClose="toggleNavOpen" />
      <main v-show="!isNavOpen">
        <router-view />
      </main>
      <Footer v-show="!isNavOpen" />
    </div>

  </div>
</template>

<style src="../style.css">
</style>

<style>
/* サービスページ共通 */
div {
  overflow: visible;
}

a {
  display: inline-block;
  margin: 0;
  width: auto;
  max-width: initial;
  height: auto;
  line-height: 1;
  border-radius: 0;
  border: none;
  background-color: initial;
  color: var(--col-black);
  box-shadow: none;
  text-align: left;
  overflow: visible;
  white-space: nowrap;
}

a:hover,
a:focus {
  text-decoration: underline;
}

button,
select,
label {
  cursor: pointer;
  overflow: visible;
}

select {
  display: flex;
  align-items: center;
	margin: 0;
	padding: 20px;
	height: auto;
  max-width: initial;
	line-height: 1;
  background-color: var(--col-grey);
	border: 1px solid var(--col-black50);
	border-radius: 6px;
}

a:focus,
button:focus,
select:focus,
label:focus {
  outline: none;
}

a:focus-visible,
button:focus-visible,
select:focus-visible,
label:focus-visible {
  outline: -webkit-focus-ring-color auto 1px;
}

.titleArea {
  padding-bottom: 20px;
}

.titleArea_title {
  padding: 40px 20px 20px;
  font-size: 28px;
  line-height: 1;
}

.titleArea_subTitle {
  padding: 0 20px 20px;
}

.titleArea_dateCategory {
  padding: 0 20px 20px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.dateCategory_date {
  font-size: 14px;
  color: #808080;
  margin-right: 20px;
}

.dateCategory_category {
  background-color: var(--col-grey);
  color: #a09a9c;
  font-size: 12px;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 20px;
  line-height: 1;
  white-space: nowrap;
}

.dateCategory_category-important {
  background-color: #ffebed;
  color: var(--col-main);
}

.header {
  background-color: var(--col-main);
  padding: 20px;
  line-height: 16px;
}

.header_button {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 56px;
  height: 56px;
  background-color: #000;
  border-bottom-left-radius: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

/* サービスページ共通 - キャンペーン詳細やお知らせ詳細で使う用 */
.mainColorText {
  color: var(--col-main);
}

.article {
  padding: 0 20px;
}

.article:last-child {
  border-bottom: 1px solid var(--col-black50);
}

.bgArea {
  background-color: #fcfaf0;
}

.bgArea_heading {
  background-color: #ebdec4;
  font-size: 20px;
  padding: 20px;
  margin-bottom: 20px;
}

.article_caution {
  margin: 20px 0;
  border-radius: 20px;
  background-color: var(--col-grey);
  padding: 20px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.article_cautionIcon {
  width: 24px;
  height: 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--col-main);
  margin-right: 10px;
}

.article_thumbnail {
  margin-bottom: 40px;
  text-align: center;
}

.article_heading2 {
  font-size: 24px;
  color: var(--col-main);
  padding-bottom: 20px;
  border-bottom: 1px solid var(--col-black50);
  margin-bottom: 20px;
}

.article_heading3 {
  font-size: 18px;
  margin-bottom: 15px;
}

.article_heading4 {
  margin-bottom: 15px;
}

.article_text {
  margin-bottom: 30px;
}

.article_text-left {
  text-align: left;
}

.article_text-right {
  text-align: right;
}

.article_textBold {
  font-weight: bold;
}

.article_textLink {
  color: var(--col-main);
  text-decoration: underline;
  white-space: initial;
}

.article_table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

.article_table th,
.article_table td {
  border: 1px solid #ccc;
  padding: 10px;
}

.textLinkArea {
  text-align: center;
  margin-bottom: 20px;
}

.textLink {
  color: var(--col-main);
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
}

.textLink_icon {
  margin-left: 10px;
}

.article_img {
  text-align: center;
  margin-bottom: 30px;
}

.article_notice {
  margin-bottom: 30px;
  border-radius: 20px;
  border: 2px solid var(--col-main);
  padding: 20px;
  color: var(--col-main);
}

.article_decoratedLinkRow {
  margin-bottom: 40px;
  text-align: center;
}

.article_decoratedLink {
  display: flex;
  max-width: 335px;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  background-color: var(--col-main);
  border-radius: 100px;
  box-shadow: 0 2px 0 #a51216;
  padding: 5px;
  margin-left: auto;
  margin-right: auto;
}

.article_decoratedTextLinkRow {
  margin-bottom: 40px;
  text-align: center;
}

.article_decoratedTextLink {
  display: flex;
  max-width: 335px;
  height: 60px;
  align-items: center;
  justify-content: center;
  background-color: var(--col-main);
  border-radius: 100px;
  box-shadow: 0 2px 0 #a51216;
  padding: 5px;
  margin-left: auto;
  margin-right: auto;
  color: white;
}

.article_listArea {
  margin-bottom: 40px;
  list-style-type: none;
  counter-reset: my-counter;
}

.article_caution .article_listArea {
  margin-bottom: 0;
}

.article_list {
  line-height: 1.75;
  padding-left: 36px;
}

.article_list:not(:last-child) {
  margin-bottom: 20px;
}

.article_list-aster {
  font-size: 14px;
}

.article_list::before {
  display: inline-flex;
  justify-content: center;
  line-height: 16px;
  margin-left: -35px;
  margin-right: 13px;
  text-align: center;
  height: 16px;
  width: 16px;
}

.article_list-dot::before {
  content: '';
  background-color: var(--col-main);
  height: 10px;
  width: 10px;
  margin-right: 19px;
  border-radius: 100%;
}

.article_list-aster::before {
  content: '※';
}

.article_list-hyphen::before {
  content: '-';
  color: var(--col-main);
}

.article_list-number::before {
  content: counter(my-counter);
  counter-increment: my-counter;
  color: var(--col-main);
  border: 1px solid var(--col-main);
  border-radius: 50%;
}

.article_list-numberNoCircle::before {
  content: counter(my-counter) '.';
  counter-increment: my-counter;
  color: var(--col-main);
}

.article_list-numberBracket::before {
  content: '(' counter(my-counter) ')';
  counter-increment: my-counter;
  color: var(--col-main);
}

.article_imgListArea {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}

.article_imgList {
  flex: auto 0 1;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  align-self: stretch;
  display: inline-flex;
  flex-direction: column;
}

.article_imgList-three {
  flex: 33% 0 1;
}

.article_imgList-four {
  flex: 25% 0 1;
}

.article_imgList-arrow {
  align-self: center;
}

.article_imgListText {
  font-size: 15px;
}

.article_compareTableArea {
  margin-bottom: 30px;
  list-style-type: none;
  display: flex;
  flex-wrap: nowrap;
  border: 1px solid var(--col-black);
}

.article_compareTable:first-child {
  border-right: 1px solid var(--col-black);
}

.article_compareTable .article_listArea {
  padding-left: 0;
  overflow: visible;
  margin-bottom: 0;
}

.article_compareTable .article_list {
  overflow: visible;
  margin-bottom: 0;
}

.article_compareTable .article_list-numberNoCircle::before,
.article_compareTable .article_list-numberBracket::before {
  color: var(--col-black);
}

.compareTable_head {
  font-weight: bold;
  border-bottom: 1px solid var(--col-black);
  padding: 5px 10px;
}

.compareTable_body {
  padding: 5px 10px;
}

.compareTable_body-diff {
  font-weight: bold;
  font-style: italic;
}

@media screen and (max-width: 807px) {
  .article_compareTableArea {
    display: block;
  }

  .article_compareTable:first-child {
    border-right: none;
    border-bottom: 1px solid var(--col-black);
  }
}

@media screen and (max-width: 807px) {
  .article_imgListArea {
    display: block;
  }

  .article_imgListArea-nowrap {
    display: flex;
  }

  .article_imgList {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .article_imgListArea-nowrap .article_imgList {
    margin-left: 10px;
    margin-right: 10px;
  }

  .article_imgList-arrow {
    transform: rotate(90deg);
  }
}

.accordion {
  overflow: hidden;
  margin-bottom: 10px;
}

.accordion .article {
  border-bottom: none;
}

.accordion_button {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  font-size: 18px;
  color: #fff;
  background-color: var(--col-main);
  z-index: 1;
  white-space: nowrap;
}

.accordion_checkbox {
  display: none;
}

.accordion_checkbox ~ .article {
  visibility: hidden;
  height: 0;
  transform: translateY(-100%);
  transition: height .3s, transform .3s, visibility .3s;
}

.accordion_checkbox:checked ~ .article {
  visibility: visible;
  height: 100%;
  transform: translateY(0);
  padding-top: 50px;
  padding-bottom: 40px;
}

.accordion_checkbox:checked ~ .article:last-child {
  padding-bottom: 0;
}

.accordion_icon {
  transition: transform .3s;
}

.accordion_checkbox:checked ~ .accordion_button .accordion_icon {
  transform: rotate(-180deg);
}

.loading {
  padding-top: 25px;
  padding-bottom: 25px;
  text-align: center;
}

.loading::before {
  content: '';
  display: inline-block;
  width: 25px;
  height: 25px;
  border: 2px solid var(--col-main);
  border-top-color: transparent;
  border-radius: 50%;
  animation: loading-animation 1s linear infinite;
}

@keyframes loading-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.notFound {
  text-align: center;
  padding: 40px 20px;
}

.mcvBtn{
	position: fixed;
	bottom: 10px;
	left: 0;
	width: 100%;
	text-align: center;
	z-index: 999;
	.btn{
		background-color: var(--col-main);
		color: white;
		padding: 20px;;
		border-radius: 10px;
		cursor: pointer;

	}
}
</style>

<style scoped>
.contents {
  max-width: 768px;
  margin: auto;
}

@media screen and (max-width: 807px) {
  .contents {
    width: 100%;
    margin: auto;
  }
}
</style>

<script>
import Header from './components/Header.vue'
import Nav from './components/Nav.vue'
import Footer from './components/Footer.vue'
import { updateMetaHead } from './lib/utils'

export default {
  props:{
  },

  data: function() {
    return {
      isNavOpen: false,
    }
  },

  mounted: async function() {
    this.updateHtmlHead(this.$route)
		this.redirectMcv()
  },

  watch: {
    $route() {
      this.updateHtmlHead(this.$route)
    }
  },

  methods: {
    toggleNavOpen: function() {
      this.isNavOpen = !this.isNavOpen
    },

    updateHtmlHead: function(to) {
      if (to.meta.title) {
        updateMetaHead(to.meta.title)
      }
    },

		//mcv対応
		redirectMcv: function(){
			const reg = /^1\d{14}$/
			const unique_id = this.$route.query.i
			if(! unique_id || ! unique_id.match(reg)) return false
			location.href = '/mcv/top?mcv_unique_id=' + unique_id
		},


  },

  components: {
    Header,
    Nav,
    Footer
  }
}
</script>
