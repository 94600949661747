<template>
  <div v-if="importantNoticeList.length > 0">
    <div
      class="noticeRow"
      v-for="(importantNotice, index) in importantNoticeList"
      :key="index"
    >
      <div class="notice">
        <div class="notice_title">
          <span class="title">
            <img :src="`${cdn}/img/top/icon_exclamation.webp`" width="3" height="12" alt="！">
          </span>
          重要なお知らせ
        </div>
        <div class="notice_text" v-html="importantNotice.text.replace(/\n/g,'<br/>')"></div>
        <div class="notice_link">
          <a class="link" :href="importantNotice.url" :target="importantNotice.isNewTab ? '_blank' : ''">
            詳しくはこちら
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.noticeRow {
  padding: 0 20px;
  margin-bottom: 20px;
}

.notice {
  background-color: var(--col-grey);
  border-radius: 20px;
  width: 100%;
  text-align: center;
  padding: 20px;
}

.notice_title {
  margin-bottom: 10px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  color: #000;
  white-space: nowrap;
}

.title {
  width: 24px;
  height: 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--col-main);
  margin-right: 10px;
}

.notice_icon {
  margin-left: 10px;
}

.link {
  color: var(--col-main);
  text-decoration: underline;
}
</style>

<script>
import { getList } from '../lib/listModules'

export default {
  props:{
  },

  data: function() {
    return {
      cdn: CDN,
      importantNoticeList: []
    }
  },

  mounted: async function() {
    this.importantNoticeList = await getList('importantNotice.json')
  },

  methods: {
  },

  components: {
  }
}
</script>
