import Vue from 'vue'
import VueGtm from 'vue-gtm'
import App from '../top/app.vue'
import router from '../top/router'

if(RAILSENV == 'production' || RAILSENV == 'staging'){
Vue.use(VueGtm, {
	id: GTM_ID
})
}

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
		router,
    render: h => h(App)
  }).$mount()
  document.body.appendChild(app.$el)

  console.log(app)
})

