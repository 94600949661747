import { render, staticRenderFns } from "./Levelup.vue?vue&type=template&id=0c9ee823&scoped=true&"
import script from "./Levelup.vue?vue&type=script&lang=js&"
export * from "./Levelup.vue?vue&type=script&lang=js&"
import style0 from "./Levelup.vue?vue&type=style&index=0&id=0c9ee823&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c9ee823",
  null
  
)

export default component.exports