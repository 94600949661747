<template>
  <div class="titleRow">
		<div class="title">
			<img v-if="imgName != ''" class="title_text" :src="`${cdn}/img/top/${imgName}`" :width="width" :height="height" :alt="alt">
      <span v-else class="title_alt">{{ alt }}</span>
		</div>
  </div>
</template>

<style scoped>
.titleRow {
  z-index: 1;
  padding: 0 20px;
  margin-bottom: 20px;
  text-align: center;
}

.title {
	max-width: 335px;
	height: 66px;
	padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
	margin-left: auto;
	margin-right: auto;
	background: linear-gradient(to right, #F6DC43, #A25C17);
	clip-path: polygon(12px 0, calc(100% - 12px) 0, 100% 33px, calc(100% - 12px) 66px, 12px 66px, 0 33px);
}

.title::before {
	content: "";
	position: absolute;
	top: 5px;
	left: 7px;
	z-index: 1;
	background-color: #4E3D1F;
	clip-path: polygon(10px 0, calc(100% - 10px) 0, 100% 28px, calc(100% - 10px) 56px, 10px 56px, 0 28px);
	display: block;
	width: calc(100% - 14px);
	height: 56px;
}

.title_text {
	z-index: 2;
}

.title_alt {
  z-index: 2;
  font-size: 24px;
  color: #f5db42;
}
</style>

<script>
export default {
  props:{
		imgName: { type: String, default: "" },
		width: { type: String, default: "" },
		height: { type: String, default: "" },
    alt: { type: String, default: "" }
  },

  data: function() {
    return {
      cdn: CDN
    }
  },

  mounted: async function() {
  },

  methods: {
  },

  components: {
  }
}
</script>
