<template>
  <div>
    <div class="header">
      <router-link :to="{ name: 'Index' }">
        <img :src="`${cdn}/img/top/logo_spm.webp`" width="209" height="15" alt="STOCKPOINT for MUFG">
      </router-link>
    </div>
    <div class="titleArea">
      <Breadcrumb />
      <h1 class="titleArea_title">レベルアップとクラスチェンジ</h1>
      <div class="titleArea_subTitle">
        様々なミッションをクリアしていくことで、キャラクターが成長していきます。
      </div>
    </div>

    <div>
      <Title :imgName="'title_level_up2.webp'" :width="'110'" :height="'20'" :alt="'レベルアップ'" />
      <div class="levelUpArea">
        <div>
          <div class="levelUp_bg">
            <img class="levelUp_bgImg" :src="`${cdn}/img/top/img_level_up_bg.webp`" width="767" height="360" alt="">
          </div>
          <img class="levelUp_chara" :src="`${cdn}/img/top/img_level_up_chara.webp`" width="175" height="294" alt="">
        </div>
        <div class="levelUp_article">
          レベルアップするためには、ミッションをクリアする必要があります。<br>
          <br>
          ひとつひとつのミッションをクリアすることで、EXP（経験値）を獲得します。
        </div>
        <Title2 :imgName="'title_mission_ex.webp'" :width="'123'" :height="'19'" :alt="'ミッションの例'" />
        <div class="levelUp_listArea">
          <ul class="article_listArea">
            <li class="article_list article_list-dot">
              毎日1回ログインする
            </li>
            <li class="article_list article_list-dot">
              保有銘柄情報をチェックする
            </li>
            <li class="article_list article_list-dot">
              Pontaと連携してポイントチャージする
            </li>
          </ul>
          <div class="levelUp_article levelUp_article-caution">
            など
          </div>
        </div>
      </div>
      <div class="missionArea">
        <div>
          <div class="mission_bg">
            <img class="mission_bgImg" :src="`${cdn}/img/top/img_mission_bg.webp`" width="767" height="360" alt="">
          </div>
          <img class="mission_chara" :src="`${cdn}/img/top/img_mission.webp`" width="357" height="271" alt="">
        </div>
        <div class="mission_article">
          また、複数のミッションをすべて達成すると、モンスターを倒して、特別EXP（経験値）を獲得できます。
        </div>
        <Title2 :imgName="'title_three_mission.webp'" :width="'136'" :height="'19'" :alt="'３種のミッション'" />
        <div class="mission_listArea">
          <ul class="article_listArea">
            <li class="article_list article_list-dot">
              毎日挑戦するデイリーミッション
            </li>
            <li class="article_list article_list-dot">
              1ヶ月内でクリアするマンスリーミッション
            </li>
            <li class="article_list article_list-dot">
              スペシャルミッション
            </li>
          </ul>
        </div>
        <div class="mission_article">
          スペシャルミッションは、時々出現するのでアプリからの通知やメールマガジンをチェックしてね。
        </div>
        <div class="mission_link">
          <router-link class="article_textLink" :to="{ name: 'Rule' }">詳しくはこちら</router-link>
        </div>
      </div>
    </div>

    <div class="unlockArea">
      <div class="unlock_card card">
        <div class="card_title">
          <img :src="`${cdn}/img/top/title_unlock2.webp`" width="175" height="47" alt="レベル毎に運用できる銘柄の世界が広がる">
        </div>
        <div class="card_img">
          <img :src="`${cdn}/img/top/img_orb2.webp`" width="335" height="157" alt="">
        </div>
        <div class="card_text">
          レベルが上がることで、ポイント運用で売買できる銘柄が、アンロックされていきます。
        </div>
        <DecoratedLink :routeName="'Brands'" :imgName="'title_to_brands.webp'" :width="'142'" :height="'17'" :alt="'銘柄リストはこちら'"/>
      </div>
    </div>

    <div class="classChangeArea">
      <Title :imgName="'title_class_change.webp'" :width="'129'" :height="'20'" :alt="'クラスチェンジ'" />
      <div class="classChange">
        <div class="classChange_bg">
          <img :src="`${cdn}/img/top/img_class_change.webp`" width="417" height="367" alt="">
        </div>
        <div class="classChange_article">
          レベルが一定に達すると、キャラクターのクラスがUPし、キャラクターの姿もバージョンアップします。
        </div>
      </div>
    </div>

    <div class="snsArea">
      <SNS />
    </div>
  </div>
</template>

<style scoped>
.levelUpArea {
  padding-bottom: 20px;
  margin-top: -63px;
}

.levelUpArea .titleRow {
  padding: 0 40px;
}

.levelUp_bg {
  height: 360px;
  margin-bottom: 30px;
  text-align: center;
  overflow: hidden;
}

.levelUp_bgImg {
  max-width: initial;
}

.levelUp_chara {
  position: absolute;
  top: 42px;
  left: 21px;
  right: -21px;
  margin: auto;
  z-index: 1;
}

.levelUp_article {
  font-size: 18px;
  line-height: 32px;
  padding: 0 20px;
  padding-bottom: 30px;
}

.levelUp_article-caution {
  font-size: 14px;
  padding-bottom: 20px;
}

.levelUp_img {
  text-align: center;
  padding-bottom: 30px;
}

.levelUp_listArea {
  margin: -55px 20px 40px;
  border-radius: 20px;
  padding-top: 55px;
  background-color: #f5f1ea;
}

.levelUp_listArea .article_listArea {
  padding: 0 20px;
  margin-bottom: 12px;
}

.levelUp_listArea .article_list {
  font-size: 14px;
  line-height: 1;
  padding-left: 20px;
}

.levelUp_listArea .article_list:not(:last-child) {
  margin-bottom: 14px;
}

.levelUp_listArea .article_list-dot::before {
  margin-right: 6px;
  margin-left: -20px;
}

.missionArea {
  padding-bottom: 20px;
}

.missionArea .titleRow {
  padding: 0 40px;
}

.mission_bg {
  height: 360px;
  margin-bottom: 30px;
  text-align: center;
  overflow: hidden;
}

.mission_bgImg {
  max-width: initial;
}

.mission_chara {
  position: absolute;
  top: 61px;
  left: -10px;
  right: 10px;
  margin: auto;
  z-index: 1;
}

.mission_article {
  font-size: 18px;
  line-height: 32px;
  padding: 0 20px;
  padding-bottom: 30px;
}

.mission_article-caution {
  font-size: 14px;
  padding-bottom: 20px;
}

.mission_link {
  text-align: center;
  padding: 0 20px 30px;
}

.mission_img {
  text-align: center;
  padding-bottom: 30px;
}

.mission_listArea {
  margin: -55px 20px 40px;
  border-radius: 20px;
  padding-top: 55px;
  background-color: #f5f1ea;
}

.mission_listArea .article_listArea {
  padding: 0 20px;
  padding-bottom: 20px;
}

.mission_listArea .article_list {
  font-size: 14px;
  line-height: 1;
  padding-left: 20px;
}

.mission_listArea .article_list:not(:last-child) {
  margin-bottom: 14px;
}

.mission_listArea .article_list-dot::before {
  margin-right: 6px;
  margin-left: -20px;
}

.unlockArea {
  background-color: #f5f1ea;
  padding: 20px;
}

.card {
  border-radius: 20px;
  background-color: #fff;
  overflow: hidden;
}

.card_title {
  background-color: var(--col-black);
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-bottom: 4px solid;
  border-image: linear-gradient(to right, #F6DC43, #A25C17) 1;
}

.card_img {
  margin-bottom: 25px;
  text-align: center;
}

.card_text {
  margin-left: 20px;
  margin-right: 20px;
  line-height: 32px;
  margin-bottom: 25px;
}

.classChangeArea {
  padding-top: 50px;
  border-bottom: 1px solid var(--col-black50);
}

.classChange {
  padding-bottom: 20px;
  margin-top: -63px;
}

.classChange_bg {
  height: 367px;
  margin-bottom: 30px;
  text-align: center;
}

.classChange_chara {
  position: absolute;
  top: 42px;
  left: 21px;
  right: -21px;
  margin: auto;
  z-index: 1;
}

.classChange_article {
  font-size: 18px;
  line-height: 32px;
  padding: 0 20px 20px;
}

.snsArea {
  padding-top: 40px;
  padding-bottom: 30px;
}
</style>

<script>
import SNS from '../components/SNS'
import Title from '../components/Title'
import Title2 from '../components/Title2'
import DecoratedLink from '../components/DecoratedLink'
import Breadcrumb from '../components/Breadcrumb'

export default {
  props:{
  },

  data: function() {
    return {
      cdn: CDN
    }
  },

  mounted: async function() {
  },

  methods: {
  },

  components: {
    SNS,
    Title,
    Title2,
    DecoratedLink,
    Breadcrumb
  }
}
</script>
