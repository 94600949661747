<template>
  <div class="bgColorLinkRow">
    <a :class="['bgColorLink', `bgColorLink-${color}`]" :href="url" target="_blank">
      <img :src="`${cdn}/img/top/${imgName}`" :width="width" :height="height" :alt="alt">
      <img class="bgColorLink_icon" :src="`${cdn}/img/top/icon_tab_white.webp`" width="14" height="14" alt="新しいタブ">
    </a>
  </div>
</template>

<style scoped>
.bgColorLinkRow {
  padding: 0 20px 30px;
  text-align: center;
}

.bgColorLink {
  display: flex;
  max-width: 335px;
  min-height: 60px;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  padding: 12px 20px 11px;
  margin-left: auto;
  margin-right: auto;
}

.bgColorLink-main {
	background: linear-gradient(to bottom, #fc4249, var(--col-main));
  box-shadow: 0 2px 0 #a51216;
}

.bgColorLink-blue {
	background: linear-gradient(to bottom, #4bebd3, #26cfb5);
  box-shadow: 0 2px 0 #18b59d;
}

.bgColorLink_icon {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 20px;
  margin: auto;
  z-index: 1;
}
</style>

<script>
export default {
  props:{
    url: { type: String, default: "" },
		imgName: { type: String, default: "" },
    width: { type: String, default: "" },
    height: { type: String, default: "" },
    alt: { type: String, default: "" },
    color: { type: String, default: "main" }
  },

  data: function() {
    return {
      cdn: CDN
    }
  },

  mounted: async function() {
  },

  methods: {
  },

  components: {
  }
}
</script>
