<template>
  <button class="header_button" @click="onClickMenu()">
    <img :src="`${cdn}/img/top/icon_humberger.webp`" width="20" height="16" alt="メニュー">
  </button>
</template>

<style scoped>
</style>

<script>
export default {
  props:{
		onClickMenu: {
			type: Function,
      default: function() {}
		}
  },

  data: function() {
    return {
      cdn: CDN
    }
  },

  mounted: async function() {
  },

  methods: {
  },

  components: {
  }
}
</script>
