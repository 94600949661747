<template>
  <div>
    <div class="header">
      <router-link :to="{ name: 'Index' }">
        <img :src="`${cdn}/img/top/logo_spm.webp`" width="209" height="15" alt="STOCKPOINT for MUFG">
      </router-link>
    </div>
    <div class="titleArea">
      <Breadcrumb />
      <h1 class="titleArea_title">アプリの特長</h1>
      <div class="titleArea_subTitle">
        STOCKPOINT for MUFGのサービス特長をご紹介いたします。
      </div>
    </div>

    <div class="thumbnailArea">
      <div class="thumbnailArea_bg">
        <img class="thumbnailArea_bgImg" :src="`${cdn}/img/top/img_feature_bg.webp`" width="1061" height="440" alt="">
      </div>
      <img class="thumbnailArea_sp" :src="`${cdn}/img/top/img_feature_sp.webp`" width="326" height="447" alt="">
    </div>

    <div class="toJourneyArea">
      <div class="feature_heading">
        <img :src="`${cdn}/img/top/title_to_journey.webp`" width="238" height="51" alt="新しいポイント運用の旅へ一歩を踏み出そう！">
      </div>
      <div class="feature_article">
        STOCKPOINT for MUFGは、お買い物やクレジットカード利用などで貯まったPontaポイント等のポイントを使い、株式や投資信託などの様々な金融商品を購入し、ポイントのまま運用体験ができるサービスです。
      </div>
      <AppStore />
    </div>

    <div class="exchangeRateArea">
      <div class="feature_heading">
        <img :src="`${cdn}/img/top/title_feature_heading.webp`" width="245" height="59" alt="お手持ちのポイントで、すぐに始められる。">
      </div>
      <div class="feature_article">
        提携先のポイントをチャージすれば、すぐにポイント運用がスタートできます。
      </div>
      <div class="exchangeRateArea_imgArea">
        <img class="exchangeRateArea_img" :src="`${cdn}/img/top/img_feature_0yen.webp`" width="267" height="205" alt="自己資金¥0">
      </div>
      <DecoratedLink :routeName="'Partners'" :imgName="'title_exchange_rate.webp'" :width="'213'" :height="'19'" :alt="'提携ポイント・交換レート'"/>
    </div>

    <div class="experienceArea">
      <div class="feature_heading">
        <img :src="`${cdn}/img/top/title_feature_heading2.webp`" width="285" height="59" alt="ポイント運用×RPGのこれまでにない新しい体験">
      </div>
      <div class="feature_article">
        STOCKPOINT for MUFGではポイント運用を体験していくことで、アプリで運用できる銘柄の選択肢がだんだんと増えていきます。<br>
        そして、アプリの進行に合わせて、あなたの経験値や金融リテラシーも高まっていくはずです。お子様でもどなたでも、ポイントだから気軽にチャレンジできるポイント運用サービスの登場です。
      </div>
      <ConceptSlide />
    </div>

    <div class="levelUpArea">
      <Title :imgName="'title_to_journey2.webp'" :width="'221'" :height="'20'" :alt="'銘柄オーブを取り戻す旅へ'" />
      <div class="levelUp">
        <div class="levelUp_card card">
          <div class="card_title">
            <img :src="`${cdn}/img/top/title_unlock.webp`" width="263" height="19" alt="レベルアップで銘柄がアンロック">
          </div>
          <div class="card_img">
            <img :src="`${cdn}/img/top/img_orb.webp`" width="335" height="164" alt="オーブ">
          </div>
          <div class="card_heading">
            運用できる銘柄は200種類以上！
          </div>
          <div class="card_text">
            ポイント運用サービスの中の様々なミッションをクリアすると、レベルが上がります。レベルが上がると、銘柄オーブが解放されて、運用できる銘柄が増えて、さらに新しいポイント運用の世界が広がります。
          </div>
          <Title2 :imgName="'title_brands.webp'" :width="'125'" :height="'19'" :alt="'運用できる銘柄'" />
          <ul class="article_listArea">
            <li class="article_list article_list-dot">
              代表的・人気の日本企業の株式銘柄
            </li>
            <li class="article_list article_list-dot">
              全世界でお馴染みの米国有名企業の株式
            </li>
            <li class="article_list article_list-dot">
              中長期で運用したい投資信託銘柄
            </li>
            <li class="article_list article_list-dot">
              全世界が注目する暗号資産や金
            </li>
          </ul>
          <div class="card_text card_text-caution">
            など
          </div>
          <DecoratedLink :routeName="'Brands'" :imgName="'title_line_up.webp'" :width="'128'" :height="'18'" :alt="'銘柄ラインアップ'"/>
        </div>

        <Title :imgName="'title_lets_support.webp'" :width="'206'" :height="'20'" :alt="'愛され企業を応援しよう'" />
        <div class="levelUp_card card">
          <div class="card_title">
            <img :src="`${cdn}/img/top/title_support_favorite.webp`" width="267" height="19" alt="好きな企業を見つけて応援しよう">
          </div>
          <div class="card_img card_img-aisare">
            <div class="aisare_bgArea">
              <img class="aisare_bg" :src="`${cdn}/img/top/img_aisare_bg.webp`" width="728" height="272" alt="">
            </div>
            <img class="aisare_fairy" :src="`${cdn}/img/top/img_fairy.webp`" width="94" height="101" alt="">
            <img class="aisare_logo" :src="`${cdn}/img/top/logo_aisare.webp`" width="199" height="45" alt="">
          </div>
          <div class="card_text">
            企業情報の紹介ページからその会社を応援したり、その会社や商品のすごいところなどを投稿することができます。
          </div>
          <div class="card_text">
            この投稿数や、獲得したハートの数もレベルアップには必要なミッションのひとつ。自分でポイント運用している会社はもちろん、他の銘柄企業にもぜひ興味を持って、投稿してみましょう。
          </div>
          <div class="card_text">
            お得な、ストックポイント株主優待プレゼントのキャンペーンもあります！
          </div>
          <BackgroundColorLink :url="'https://aisarekigyo.stockpoint.jp'" :imgName="'title_aisare.webp'" :width="'147'" :height="'38'" :alt="'愛され企業さーち'" :color="'blue'" />
        </div>

        <Title :imgName="'title_exchange_to_stock.webp'" :width="'225'" :height="'20'" :alt="'本物の「株」に交換できる'" />
        <div class="levelUp_card card">
          <div class="card_title">
            <img :src="`${cdn}/img/top/title_stock_holder.webp`" width="243" height="47" alt="ポイントが１株以上になったらリアル「株主」になれる！">
          </div>
          <div class="card_img card_img-stockHolder">
            <div class="stockHolder_bgArea">
              <img class="stockHolder_bg" :src="`${cdn}/img/top/img_stock_holder_bg.webp`" width="728" height="832" alt="">
            </div>
            <img class="stockHolder_chara" :src="`${cdn}/img/top/img_stock_holder_chara.webp`" width="112" height="125" alt="">
          </div>
          <div class="card_text">
            日本株式の個別銘柄で「株式にできる」銘柄は、StockPoint for CONNECTに会員登録して、STOCKPOINT for MUFGと連携すれば、現物の株式にすることができます。
          </div>
          <BackgroundColorLink :url="'https://spm.stockpoint.jp/howto/stock'" :imgName="'title_to_stock.webp'" :width="'111'" :height="'17'" :alt="'株式にするには'"/>
        </div>
      </div>
    </div>

    <div class="downloadArea">
      <Download />
    </div>

    <div class="snsArea">
      <SNS />
    </div>
  </div>
</template>

<style scoped>
.thumbnailArea {
  padding-bottom: 36px;
}

.thumbnailArea_bg {
  height: 440px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.thumbnailArea_bgImg {
  max-width: initial;
}

.thumbnailArea_sp {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
}

.toJourneyArea {
  padding: 25px 20px 40px;
}

.feature_heading {
  padding-bottom: 30px;
  text-align: center;
}

.feature_article {
  line-height: 32px;
  padding-bottom: 30px;
  text-align: left;
}

.exchangeRateArea {
  background-color: #f5f1ea;
  padding: 40px 20px 10px;
}

.exchangeRateArea_imgArea {
  border-radius: 20px;
  background: linear-gradient(to right, #F6DC43, #A25C17);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 30px;
  margin-bottom: 30px;
}

.exchangeRateArea_imgArea::before {
  content: "";
  position: absolute;
  top: 4px;
  right: 4px;
  bottom: 4px;
  left: 4px;
  border-radius: 18px;
  background-color: #fff;
  z-index: 1;
}

.exchangeRateArea_img {
  z-index: 2;
}

.exchangeRateArea .decoratedLinkRow {
  padding-left: 0;
  padding-right: 0;
}

.experienceArea {
  padding: 50px 0 10px;
}

.experienceArea .feature_heading,
.experienceArea .feature_article {
  padding-left: 20px;
  padding-right: 20px;
}

.levelUpArea {
  margin-bottom: 20px;
}

.levelUp {
  margin-top: -63px;
  background-color: #f5f1ea;
  padding: 42px 0 20px;
}

.levelUp .titleRow {
  margin-bottom: 20px;
}

.card {
  border-radius: 20px;
  background-color: #fff;
  overflow: hidden;
  margin: 0 20px 50px;
}

.levelUp_card:last-child {
  margin-bottom: 0;
}

.card_title {
  background-color: var(--col-black);
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-bottom: 4px solid;
  border-image: linear-gradient(to right, #F6DC43, #A25C17) 1;
}

.card_img {
  margin-bottom: 25px;
  text-align: center;
}

.card_heading {
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 18px;
  color: var(--col-main);
}

.card_text {
  margin-left: 20px;
  margin-right: 20px;
  line-height: 32px;
  margin-bottom: 25px;
}

.card_text-caution {
  font-size: 14px;
}

.levelUpArea .article_listArea {
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 15px;
}

.levelUp .article_list:not(:last-child) {
  margin-bottom: 15px;
}

.card_img-aisare {
  margin-bottom: 37px;
}

.card_img-stockHolder {
  margin-bottom: 50px;
}

.aisare_bgArea {
  height: 125px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aisare_bg {
  width: 100%;
  max-height: initial;
}

.aisare_fairy {
  position: absolute;
  top: 35px;
  right: -109px;
  left: 109px;
  z-index: 2;
  margin: auto;
  width: 94px;
}

.aisare_logo {
  position: absolute;
  top: 40px;
  right: 52px;
  left: -52px;
  z-index: 1;
  margin: auto;
}

.stockHolder_bgArea {
  height: 125px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stockHolder_bg {
  width: 100%;
  max-height: initial;
  top: 50%;
}

.stockHolder_chara {
  position: absolute;
  top: 15px;
  right: 0;
  left: 0;
  z-index: 1;
  margin: auto;
  width: 112px;
}

.downloadArea {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--col-black50);
}

.snsArea {
  padding-top: 40px;
  padding-bottom: 30px;
}
</style>

<script>
import AppStore from '../components/AppStore'
import DecoratedLink from '../components/DecoratedLink'
import ConceptSlide from '../components/ConceptSlide'
import Title from '../components/Title'
import Title2 from '../components/Title2'
import BackgroundColorLink from '../components/BackgroundColorLink'
import Download from '../components/Download'
import SNS from '../components/SNS'
import Breadcrumb from '../components/Breadcrumb'

export default {
  props:{
  },

  data: function() {
    return {
      cdn: CDN
    }
  },

  mounted: async function() {
  },

  methods: {
  },

  components: {
    AppStore,
    DecoratedLink,
    ConceptSlide,
    Title,
    Title2,
    BackgroundColorLink,
    Download,
    SNS,
    Breadcrumb
  }
}
</script>
