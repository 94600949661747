<template>
  <div>
    <div class="header">
      <router-link :to="{ name: 'Index' }">
        <img :src="`${cdn}/img/top/logo_spm.webp`" width="209" height="15" alt="STOCKPOINT for MUFG">
      </router-link>
    </div>
    <div v-if="getIsEnd(campaignList)" class="article">
      <div class="article_caution">
        <span class="article_cautionIcon">
          <img src="/cdn/img/top/icon_exclamation.webp" width="3" height="12" alt="！">
        </span>
        以下のキャンペーンは終了しています
      </div>
    </div>
    <div v-if="article === ''" class="loading" />
    <div v-else v-html="article"></div>
    <div class="campaignFooter">
      <SNS />
      <BorderLink :routeName="'Campaign'" :text="'キャンペーン一覧へもどる'" />
    </div>
  </div>
</template>

<style scoped land="scss">
.campaignFooter {
  padding: 40px 20px 0;
}
</style>

<script>
import SNS from '../components/SNS'
import BorderLink from '../components/BorderLink'
import axios from 'axios'
import { getList } from '../lib/listModules'
import { getServerDateString } from '../lib/dateModules'

export default {
  props:{
  },

  data: function() {
    return {
      cdn: CDN,
      article: '',
      campaignList: []
    }
  },

  mounted: async function() {
    this.campaignList = await getList('campaign.json')
    this.article = await this.getArticleHTML()
  },

  methods: {
    getArticleHTML: async function() {
      const res = await axios
        .get(`/static/campaign/${this.$route.params.yymmdd_nn}.html`)
        .then(function (res) {
          return res.data
        })
        .catch(function (e) {
          console.log(e)
          return false
        })

      if (!res || res.indexOf('ページが見つかりません。<br>恐れ入りますが最初からやり直してください。') >= 0) {
        return '<div class="notFound">ページが見つかりません。<br>恐れ入りますが最初からやり直してください。</div>'
      } else {
        return res.replace(/src="\/cdn/g, `src="${this.cdn}`)
      }
    },

    getIsEnd: function(list) {
      if (list.length > 0) {
        const campaign = list.find(
          (campaign) => campaign.url == `/top/campaign/${this.$route.params.yymmdd_nn}`
        )

        if (campaign) {
          const serverDate = getServerDateString(NOW_DATE)
          const isEnd = campaign.endDate && campaign.endDate < serverDate

          return isEnd
        } else {
          return false
        }
      } else {
        return false
      }
    }
  },

  components: {
    SNS,
    BorderLink
  }
}
</script>
