<template>
  <div class="decoratedLinkRow">
    <router-link class="decoratedLink" :to="{ name: routeName }">
      <img :src="`${cdn}/img/top/icon_decorated_link_left.webp`" width="25" height="50" alt="">
      <img
        class="decoratedLink_title"
        :src="`${cdn}/img/top/${imgName}`"
        :width="width"
        :height="height"
        :alt="alt"
      >
      <img :src="`${cdn}/img/top/icon_decorated_link_right.webp`" width="25" height="50" alt="">
    </router-link>
  </div>
</template>

<style scoped>
.decoratedLinkRow {
  padding: 0 20px 30px;
  text-align: center;
}

.decoratedLink {
  display: flex;
  max-width: 335px;
  height: 60px;
  align-items: center;
  justify-content: space-between;
  background-color: var(--col-main);
  border-radius: 100px;
  box-shadow: 0 2px 0 #a51216;
  padding: 5px;
  margin-left: auto;
  margin-right: auto;
}

.decoratedLink_title {
  max-width: calc(100% - 60px);
}
</style>

<script>
export default {
  props:{
    routeName: { type: String, default: "" },
		imgName: { type: String, default: "" },
		width: { type: String, default: "" },
		height: { type: String, default: "" },
    alt: { type: String, default: "" }
  },

  data: function() {
    return {
      cdn: CDN
    }
  },

  mounted: async function() {
  },

  methods: {
  },

  components: {
  }
}
</script>
