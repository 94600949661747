<template>
  <div>
    <div class="header">
      <router-link :to="{ name: 'Index' }">
        <img :src="`${cdn}/img/top/logo_spm.webp`" width="209" height="15" alt="STOCKPOINT for MUFG">
      </router-link>
    </div>
    <div class="titleArea">
      <h1 class="titleArea_title">キャンペーン一覧</h1>
      <div class="titleArea_subTitle">
        STOCKPOINT for MUFGのキャンペーン一覧です。
      </div>
    </div>

    <div class="campaignNavArea">
      <div class="campaignNav">
        <button
          :class="['campaignNav_button', {'campaignNav_button-now': isNowNav('run')}]"
          @click="toggleNav('run')"
        >実施中のキャンペーン</button>
        <button
          :class="['campaignNav_button', {'campaignNav_button-now': isNowNav('end')}]"
          @click="toggleNav('end')"
        >終了したキャンペーン</button>
      </div>
    </div>

    <CampaignList :campaignList="getFilterList(campaignList)" :navStatus="navStatus" />
  </div>
</template>

<style scoped>
.campaignNavArea {
  background-color: var(--col-black);
  border-top: 4px solid;
  border-image: linear-gradient(to right, #F6DC43, #A25C17) 1;
  padding: 25px 20px 0;
}

.campaignNav {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.campaignNav_button {
  padding-bottom: 20px;
  border-bottom: 6px solid var(--col-black);
  color: #fff;
  text-shadow: 0 2px 0 #140D02;
}

.campaignNav_button:not(:last-child) {
  margin-right: 28px;
}

.campaignNav_button:hover,
.campaignNav_button:focus,
.campaignNav_button-now {
  color: #f5db42;
  border-color: #f5db42;
}
</style>

<script>
import CampaignList from '../components/CampaignList'
import { getList } from '../lib/listModules'
import { getServerDateString } from '../lib/dateModules'

export default {
  props:{
  },

  data: function() {
    return {
      cdn: CDN,
      navStatus: 'run',
      campaignList: []
    }
  },

  mounted: async function() {
    this.campaignList = await getList('campaign.json')
  },

  methods: {
    toggleNav: function(status) {
      this.navStatus = status
    },

    isNowNav: function(status) {
      return this.navStatus == status
    },

    getFilterList: function(list) {
      if (list.length > 0) {
        const serverDate = getServerDateString(NOW_DATE)
        const listExceptedFuture = list.filter(
          (campaign) => !campaign.releaseDate || campaign.releaseDate <= serverDate
        )
        if (this.navStatus == 'run') {
          return listExceptedFuture.filter(
            (campaign) => !campaign.endDate || serverDate <= campaign.endDate
          )
        } else if (this.navStatus == 'end') {
          return listExceptedFuture.filter(
            (campaign) => campaign.endDate && campaign.endDate < serverDate
          )
        } else {
          return listExceptedFuture
        }
      } else {
        return list
      }
    }
  },

  components: {
    CampaignList
  }
}
</script>
