<template>
  <div class="navArea">
    <div class="header">
      <router-link :to="{ name: 'Index' }" @click.native="onClickClose()">
        <img :src="`${cdn}/img/top/logo_spm.webp`" width="209" height="15" alt="STOCKPOINT for MUFG">
      </router-link>
      <button class="header_button" @click="onClickClose()">
        <img :src="`${cdn}/img/top/icon_close.webp`" width="16" height="16" alt="閉じる">
      </button>
    </div>

    <div class="nav">
      <div class="nav_top">
        <div class="linkRow">
          <router-link
            :class="['link', {'link-now': isNow('Index') || isNow('Index2')}]"
            :to="{ name: 'Index' }"
            @click.native="onClickClose()"
          >
            トップ
          </router-link>
        </div>
      </div>
      <div class="nav_pages">
        <div class="linkRow">
          <router-link
            :class="['link', {'link-now': isNow('Campaign') || isNow('CampaignDetail')}]"
            :to="{ name: 'Campaign' }"
            @click.native="onClickClose()"
          >
            キャンペーン
          </router-link>
        </div>
        <div class="linkRow">
          <router-link
            :class="['link', {'link-now': isNow('Feature')}]"
            :to="{ name: 'Feature' }"
            @click.native="onClickClose()"
          >
            アプリの特長
          </router-link>
          <div class="linkRow-child">
            <router-link
              :class="['link', {'link-now': isNow('Partners')}]"
              :to="{ name: 'Partners' }"
              @click.native="onClickClose()"
            >
              提携ポイント・交換レート
            </router-link>
          </div>
          <div class="linkRow-child">
            <router-link
              :class="['link', {'link-now': isNow('Brands')}]"
              :to="{ name: 'Brands' }"
              @click.native="onClickClose()"
            >
              銘柄ラインアップ
            </router-link>
          </div>
        </div>
        <div class="linkRow">
          <router-link
            :class="['link', {'link-now': isNow('Story')}]"
            :to="{ name: 'Story' }"
            @click.native="onClickClose()"
          >
            ストーリーとキャラクター
          </router-link>
          <div class="linkRow-child">
            <router-link
              :class="['link', {'link-now': isNow('Sodate')}]"
              :to="{ name: 'Sodate' }"
              @click.native="onClickClose()"
            >
              騎士ソダテ
            </router-link>
          </div>
          <div class="linkRow-child">
            <router-link
              :class="['link', {'link-now': isNow('Manabi')}]"
              :to="{ name: 'Manabi' }"
              @click.native="onClickClose()"
            >
              魔法使いマナビ
            </router-link>
          </div>
        </div>
        <div class="linkRow">
          <router-link
            :class="['link', {'link-now': isNow('Levelup')}]"
            :to="{ name: 'Levelup' }"
            @click.native="onClickClose()"
          >
            レベルアップとクラスチェンジ
          </router-link>
        </div>
        <div class="linkRow">
          <router-link
            :class="['link', {'link-now': isNow('Rule')}]"
            :to="{ name: 'Rule' }"
            @click.native="onClickClose()"
          >
            ゲームルール
          </router-link>
        </div>
        <div class="linkRow">
          <router-link
            :class="['link', {'link-now': isNow('News') || isNow('NewsDetail')}]"
            :to="{ name: 'News' }"
            @click.native="onClickClose()"
          >
            お知らせ
          </router-link>
        </div>
        <div class="linkRow">
          <a class="link" href="https://spm-help.stockpoint.jp/hc/ja" target="_blank">
            よくあるご質問
            <img class="link_icon" :src="`${cdn}/img/top/icon_tab.webp`" width="20" height="20" alt="新しいタブ">
          </a>
        </div>
      </div>
      <div class="nav_others">
        <div class="linkRow">
          <a class="link" href="https://spm-help.stockpoint.jp/hc/ja/requests/new" target="_blank">
            お問い合わせ
            <img class="link_icon" :src="`${cdn}/img/top/icon_tab.webp`" width="20" height="20" alt="新しいタブ">
          </a>
        </div>
        <div class="linkRow">
          <a class="link" :href="`${cdn}/static/terms.html`" target="_blank">
            会員規約・プライバシーポリシー
            <img class="link_icon" :src="`${cdn}/img/top/icon_tab.webp`" width="20" height="20" alt="新しいタブ">
          </a>
        </div>
      </div>
    </div>

    <div class="appStoreArea">
      <div class="appStoreArea_title">
        <img :src="`${cdn}/img/top/title_download.webp`" width="105" height="13" alt="DOWNLOAD">
      </div>
      <AppStore />
    </div>

    <div class="companyArea">
      <div class="companyRow">
        <a class="company" href="https://stockpoint.co.jp" target="_blank">
          STOCKPOINT株式会社
          <img class="company_icon" :src="`${cdn}/img/top/icon_tab.webp`" width="20" height="20" alt="新しいタブ">
        </a>
      </div>
      <div class="companyRow">
        <a class="company" href="https://www.bk.mufg.jp" target="_blank">
          三菱UFJ銀行
          <img class="company_icon" :src="`${cdn}/img/top/icon_tab.webp`" width="20" height="20" alt="新しいタブ">
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped>
.navArea {
  background: #fff;
  border-left: 1px solid var(--col-brown-dark);
  border-right: 1px solid var(--col-brown-dark);
}

.nav {
  padding: 30px 30px 10px;
}

.nav_top,
.nav_pages {
  border-bottom: 1px solid var(--col-brown-dark);
}

.nav_pages,
.nav_others {
  padding-top: 30px;
}

.linkRow {
  margin-bottom: 30px;
}

.nav_top .linkRow,
.nav_others .linkRow {
  margin-bottom: 20px;
}

.linkRow-child {
  margin-top: 20px;
  margin-left: 20px;
}

.link {
  display: inline-flex;
  align-items: center;
  font-size: 18px;
}

.linkRow-child .link,
.nav_others .link {
  font-size: 16px;
}

.link-now {
  color: var(--col-main);
}

.link_icon {
  margin-left: 10px;
}

.appStoreArea {
  background-color: #f5f1ea;
  padding: 30px;
}

.appStoreArea_title {
  margin-bottom: 15px;
}

.companyArea {
  padding: 30px;
}

.companyRow {
  margin-bottom: 20px;
}

.company {
  display: inline-flex;
  align-items: center;
}

.company_icon {
  margin-left: 10px;
}
</style>

<script>
import AppStore from './AppStore'

export default {
  props:{
		onClickClose: {
			type: Function,
      default: function() {}
		}
  },

  data: function() {
    return {
      cdn: CDN
    }
  },

  mounted: async function() {
  },

  methods: {
    isNow: function(name) {
      if (name == this.$route.name) {
        return true
      } else {
        return false
      }
    }
  },

  components: {
    AppStore
  }
}
</script>
