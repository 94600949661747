<template>
  <div class="borderButtonRow">
    <button class="borderButton" @click="onClickButton">
      {{ text }}
    </button>
  </div>
</template>

<style scoped>
.borderButtonRow {
  padding-top: 30px;
  padding-bottom: 40px;
  text-align: center;
}

.borderButton {
  display: flex;
  width: 100%;
  max-width: 335px;
  height: 50px;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--col-main);
  border-radius: 25px;
  padding: 17px 18px;
  margin-left: auto;
  margin-right: auto;
  color: var(--col-main);
}
</style>

<script>
export default {
  props:{
    onClickButton: { type: Function, default: function() {} },
    text: { type: String, default: "" }
  },

  data: function() {
    return {
      cdn: CDN
    }
  },

  mounted: async function() {
  },

  methods: {
  },

  components: {
  }
}
</script>
