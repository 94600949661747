<template>
  <div>
    <div class="header">
      <router-link :to="{ name: 'Index' }">
        <img :src="`${cdn}/img/top/logo_spm.webp`" width="209" height="15" alt="STOCKPOINT for MUFG">
      </router-link>
    </div>
    <div class="titleArea">
      <Breadcrumb />
      <h1 class="titleArea_title">ゲームルール</h1>
    </div>

    <div class="article">
      <h2 class="article_heading2">
        キャラクターのレベル(LV)を上げよう
      </h2>
      <div class="ruleArea">
        <div class="article_img">
          <img :src="`${cdn}/img/top/img_rule_01.webp`" width="275" height="591" alt="">
        </div>
        <div>
          <p class="article_text">
            キャラクターのレベル(LV)によって、銘柄がアンロックされ、ポイント運用できる世界が広がっていきます。
          </p>
          <p class="article_text">
            また、ボーナスキャンペーンでは、レベルに応じて参加できるイベントも登場することがあります。
          </p>
          <p class="article_text">
            レベルに応じて、クラスが上がると、キャラクターも成長！<br>
            キャラクターの右下にある「ミッション」をクリックしてミッションクリアにもチャレンジしてみましょう。
          </p>
        </div>
      </div>

      <h2 class="article_heading2">
        ミッションをクリアしよう
      </h2>
      <div class="ruleArea">
        <div class="article_img">
          <img :src="`${cdn}/img/top/img_rule_02.webp`" width="280" height="606" alt="">
        </div>
        <div>
          <p class="article_text">
            ミッションには、
            <ul class="article_listArea">
              <li class="article_list article_list-dot">
                デイリーミッション
              </li>
              <li class="article_list article_list-dot">
                マンスリーミッション
              </li>
              <li class="article_list article_list-dot">
                スペシャルミッション
              </li>
            </ul>
            があります。
          </p>
          <p class="article_text">
            ミッションをクリアすると、ボーナス経験値がもらえるので、レベルアップしやすくなります。
          </p>
          <p class="article_text">
            デイリーミッションは、
            <ul class="article_listArea">
              <li class="article_list">
                ログインする
              </li>
              <li class="article_list">
                保有銘柄にスタンプ
              </li>
            </ul>
            です。<br>
            それぞれ、ここで「受け取る」をクリックしないと経験値を受け取れないので、忘れないよう注意ください。
          </p>
        </div>
      </div>

      <h2 class="article_heading2">
        保有銘柄にスタンプしよう
      </h2>
      <div class="ruleArea">
        <div class="article_img">
          <img :src="`${cdn}/img/top/img_rule_03.webp`" width="280" height="597" alt="">
        </div>
        <div>
          <p class="article_text">
            保有している銘柄の情報ページで、銘柄チェックをすると、1日1回経験値がもらえます。
          </p>
          <p class="article_text">
            今後はこのスタンプの履歴日記機能や、その横の☆印のお気に入り機能など、順次リリースしていく予定です。
          </p>
        </div>
      </div>

      <h2 class="article_heading2">
        マンスリーミッションにチャレンジ
      </h2>
      <div class="ruleArea">
        <div class="article_img">
          <img :src="`${cdn}/img/top/img_rule_04.webp`" width="280" height="606" alt="">
        </div>
        <div>
          <p class="article_text">
            マンスリーミッションは、その当月中に達成してクリアするミッションです。
            <ul class="article_listArea">
              <li class="article_list article_list-dot">
                SPM（本アプリの略称です）にログインする。
              </li>
              <li class="article_list article_list-dot">
                保有銘柄にスタンプ
              </li>
            </ul>
            の他に、
            <ul class="article_listArea">
              <li class="article_list article_list-dot">
                銘柄の売買の回数
              </li>
              <li class="article_list article_list-dot">
                Pontaからのチャージ金額累計
              </li>
              <li class="article_list article_list-dot">
                愛され企業サーチで書いた投稿にハートをもらった
              </li>
            </ul>
            があります。
          </p>
          <p class="article_text">
            これも、毎月月末にクリアされますので、達成したら受け取りを忘れずに。
          </p>
        </div>
      </div>

      <h2 class="article_heading2">
        お気に入り企業への応援メッセージを投稿しよう
      </h2>
      <div class="ruleArea">
        <div class="article_img">
          <img :src="`${cdn}/img/top/img_rule_05.webp`" width="280" height="608" alt="">
        </div>
        <div>
          <p class="article_text">
            気になる企業、お気に入りの企業をもう少し知りたい方は、「この企業をもっと知る」から、当社が運営する「愛され企業さーち」で見ることができます。
          </p>
          <p class="article_text">
            ここで、「みんなのコメント」からあなたが投稿したコメントにハートマークがつくと、経験値がアップします。
          </p>
          <p class="article_text">
            他のみんなのコメントも参考にしながら、企業や他のみんながうれしくなる情報を投稿してください！
          </p>
        </div>
      </div>

      <h2 class="article_heading2">
        スペシャルミッションはどんどん変わる
      </h2>
      <div class="ruleArea">
        <div class="article_img">
          <img :src="`${cdn}/img/top/img_rule_06.webp`" width="280" height="606" alt="">
        </div>
        <div>
          <p class="article_text">
            スペシャルミッションは、その名の通り、スペシャルなミッション。
          </p>
          <p class="article_text">
            期間限定で設定される場合もあるので、こまめにチェックして経験値ゲットしてみましょう！
          </p>
        </div>
      </div>

      <h2 class="article_heading2">
        自分のキャラクターのレベルを確認
      </h2>
      <div class="ruleArea">
        <div class="article_img">
          <img :src="`${cdn}/img/top/img_rule_07.webp`" width="279" height="602" alt="">
        </div>
        <div>
          <p class="article_text">
            アプリのホーム画面左上にある、キャラクターの顔をクリックすると、自分の経験値と経験値付与履歴が確認できます。
          </p>
          <p class="article_text">
            時々発生する、スペシャルミッションをうまく利用しながら、経験値を獲得し、レベルアップしてみましょう。
          </p>
        </div>
      </div>
    </div>

    <div class="snsArea">
      <SNS />
    </div>
  </div>
</template>

<style scoped>
.article_heading2 {
  padding-top: 20px;
  margin-bottom: 30px;
}

.ruleArea {
  display: flex;
  flex-wrap: nowrap;
}

.article_img {
  flex: 0 0 300px;
  padding-right: 20px;
}

@media screen and (max-width: 600px) {
  .ruleArea {
    display: block;
  }

  .article_img {
    padding-right: 0;
  }
}

.article_listArea {
  padding: 15px 0;
  margin-bottom: 0;
}

.article_list:not(:last-child) {
  margin-bottom: 5px;
}

.snsArea {
  padding-top: 40px;
  padding-bottom: 30px;
}
</style>

<script>
import SNS from '../components/SNS'
import Breadcrumb from '../components/Breadcrumb'

export default {
  props:{
  },

  data: function() {
    return {
      cdn: CDN
    }
  },

  mounted: async function() {
  },

  methods: {
  },

  components: {
    SNS,
    Breadcrumb
  }
}
</script>
