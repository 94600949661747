
<template>
  <div class="lpPonta">
    <div class="titleArea">
      <img class="titleAreaImg titleAreaImg-pc" src="/static/campaign/img/220712_01_09.jpg" alt="総額30万Pontaポイントを山分けしてプレゼント">
      <img class="titleAreaImg titleAreaImg-sp" src="/static/campaign/img/220712_01_10.jpg" alt="総額30万Pontaポイントを山分けしてプレゼント">
    </div>
    <div class="article">
      <div class="article_decoratedTextLinkRow">
        <a id="topLpPontaEntry1" class="article_decoratedTextLink" href="/user/create/ponta">
          エントリーはこちら
        </a>
        <ul class="article_listArea">
          <li class="article_list article_list-aster">
            新規会員登録画面に遷移します。
          </li>
        </ul>
      </div>
      <h3 class="article_heading3 textCenterArea">
        エントリーは以下の条件を満たすだけ！<br>
        期間中に、
      </h3>
      <div class="article_imgListArea article_imgListArea-condition">
        <div class="article_imgList article_imgList-three">
          <div>STOCKPOINT for MUFGアプリを新規インストール＆会員登録</div>
          <div class="spmApp">
            <img class="spmApp_img" src="/static/campaign/img/220712_01_01.png" alt="STOCKPOINT for MUFGアプリ">
            <AppStore />
          </div>
        </div>
        <div class="article_imgList article_imgList-arrow article_imgList-arrowBig">＋</div>
        <div class="article_imgList article_imgList-three">
          <div>Pontaをアプリに連携＆チャージ</div>
          <div>
            <img src="/static/campaign/img/220712_01_02.png" alt="Ponta">
          </div>
        </div>
      </div>
    </div>

    <div class="article bgColorArea">
      <h3 class="article_heading3 textCenterArea">
        STOCKPOINT for MUFGとは？？
      </h3>
      <p class="article_text">
        STOCKPOINT for MUFGは、お買い物やクレジットカード利用でたまったPontaポイント等のポイントを使い、
        日本株（ETF含む）、米国株、投資信託、そして暗号資産などの様々な金融商品を購入し、
        ポイントのまま運用体験ができるサービスです。
      </p>
    </div>

    <div class="article">
      <h3 class="article_heading3 article_heading3-topMargin">
        Pontaとの連携＆チャージ方法
      </h3>
      <ul class="article_listArea">
        <li class="article_list article_list-number">
          STOCKPOINT for MUFGの会員登録
          <div class="article_img">
            <img src="/static/campaign/img/220712_01_03.png" alt="会員登録">
          </div>
        </li>
        <li class="article_list article_list-number">
          STOCKPOINT for MUFGアプリをダウンロードして会員登録した情報でログイン
          <div class="article_img">
            <img src="/static/campaign/img/220712_01_04.png" alt="ログイン">
          </div>
        </li>
        <li class="article_list article_list-number">
          <div class="article_imgListArea">
            <div class="article_imgList article_imgList-three">
              <img src="/static/campaign/img/220712_01_05.png" alt="スクリーンショット">
              <div class="article_imgListText">
                (1)ホーム画面左下の「チャージ」、または、ホーム画面中央の表示ポイント右側の「チャージ」をタップします。
              </div>
            </div>
            <div class="article_imgList article_imgList-arrow">→</div>
            <div class="article_imgList article_imgList-three">
              <img src="/static/campaign/img/220712_01_06.png" alt="スクリーンショット">
              <div class="article_imgListText">
                (2)Pontaポイント右側の「ID連携>」をタップした後に表示される「ID連携する」をタップします。
              </div>
            </div>
            <div class="article_imgList article_imgList-arrow">→</div>
          </div>
          <div class="article_imgListArea">
            <div class="article_imgList article_imgList-three">
              <img src="/static/campaign/img/220712_01_07.png" alt="スクリーンショット">
              <div class="article_imgListText">
                (3)PontaWebの画面に移行しますので、リクルートID・PWを入力して、Ponta会員IDとの連携を実行します。
                ID連携が完了すると、Pontaポイントの右側の表示が「ID連携>」から「チャージ」に変わります。
              </div>
            </div>
            <div class="article_imgList article_imgList-arrow">→</div>
            <div class="article_imgList article_imgList-three">
              <img src="/static/campaign/img/220712_01_08.png" alt="スクリーンショット">
              <div class="article_imgListText">
                (4)「チャージ」をタップして、保有されているPontaポイントをキャッシュポイントへ交換します。
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="article_decoratedTextLinkRow">
        <a id="topLpPontaEntry2" class="article_decoratedTextLink" href="/user/create/ponta">
          エントリーはこちら
        </a>
        <ul class="article_listArea">
          <li class="article_list article_list-aster">
            新規会員登録画面に遷移します。
          </li>
        </ul>
      </div>
    </div>

    <div class="article bgColorArea">
      <h2 class="article_heading2">
        キャンペーン詳細
      </h2>
      <h3 class="article_heading3">
        【キャンペーン期間】
      </h3>
      <p class="article_text">
        2022年7月12日(火)0:00〜8月22日(月)23:59
      </p>
      <h3 class="article_heading3">
        【キャンペーン概要】
      </h3>
      <p class="article_text">
        期間中に、STOCKPOINT for MUFGを新規でインストールして頂き、
        Ponta会員IDを連携のうえ1ポイント以上チャージしていただくと、
        総額30万Pontaポイントを山分けしてプレゼント！
      </p>
      <ul class="article_listArea">
        <li class="article_list article_list-aster">
          期間中に、STOCKPOINT for MUFGをアンインストール、またはPonta会員ID連携を解除した場合は対象外です。
        </li>
      </ul>
      <h3 class="article_heading3">
        【特典内容】
      </h3>
      <h1 class="article_text">
        総額30万Pontaポイントを山分けしてプレゼント！
      </h1>
      <ul class="article_listArea">
        <li class="article_list article_list-dot">
          対象者数によって加算されるPontaポイント数が異なります。
        </li>
        <li class="article_list article_list-dot">
          山分けされるポイント数は、小数点以下切り捨てとなります。
        </li>
        <li class="article_list article_list-dot">
          対象者数が30万名様を上回った場合は、全員に1Pontaポイントをプレゼントいたします。
        </li>
        <li class="article_list article_list-dot">
          お一人様あたりの加算されるポイント数の上限は、100Pontaポイントまでとさせていただきます。
        </li>
        <li class="article_list article_list-dot">
          本キャンペーンによって加算されるポイント数のお知らせは、ポイントの加算をもって代えさせていただきます。
        </li>
        <li class="article_list article_list-dot">
          本キャンペーンによって加算されるポイントは、ボーナスポイントとして2022年9月末頃加算予定です。
          <ul class="article_listArea">
            <li class="article_list article_list-aster">
              諸事情により遅れる場合もございますので、あらかじめご了承ください。
            </li>
          </ul>
        </li>
        <li class="article_list article_list-dot">
          加算ポイントは、Pontaアプリのポイント履歴からご確認いただけます。
        </li>
      </ul>
    </div>

    <div class="article">
      <h3 class="article_heading3">
        【注意事項】
      </h3>
      <ul class="article_listArea">
        <li class="article_list article_list-dot">
          キャンペーン期間中〜ポイント加算時点のいずれかまでに、以下の条件に当てはまった場合、特典獲得の<span class="article_textBold">権利を失います</span>。
          <ul class="article_listArea">
            <li class="article_list article_list-number">
              Pontaを退会された場合
            </li>
            <li class="article_list article_list-number">
              STOCKPOINT for MUFGをアンインストールされた場合
            </li>
            <li class="article_list article_list-number">
              STOCKPOINT for MUFGとPonta会員IDの連携＆1ポイント以上のチャージが行われていない場合
            </li>
          </ul>
        </li>
        <li class="article_list article_list-dot">
          キャンペーンへの参加・利用状況についてのお問合わせには対応いたしかねます。
        </li>
        <li class="article_list article_list-dot">
          STOCKPOINT for MUFGのアプリ登録内容に虚偽・不備がある場合は特典獲得の権利を失います。
        </li>
        <li class="article_list article_list-dot">
          本キャンペーンの内容は、事前に告知なく変更、または終了させていただく場合がありますので、予めご了承願います。
        </li>
        <li class="article_list article_list-dot">
          その他、(株)ロイヤリティ マーケティング、STOCK POINT(株)が不正とみなしたアプリ会員、Ponta会員は対象外とさせていただく場合がございます。
        </li>
      </ul>
      <h3 class="article_heading3">
        【お問い合わせ】
      </h3>
      <ul class="article_listArea">
        <li class="article_list article_list-dot">
          本キャンペーン、Pontaサービスについては
          <a class="article_textLink textLink" href="https://faq.ponta.jp/as/scope3/ponta/pontaweb_pc/" target="_blank">
            こちら
            <img class="textLink_icon" :src="`${cdn}/img/top/icon_tab_red.svg`" alt="新しいタブ">
          </a>
        </li>
        <li class="article_list article_list-dot">
          STOCKPOINT for MUFGについては
          <a class="article_textLink textLink" href="https://spm-help.stockpoint.jp/hc/ja" target="_blank">
            こちら
            <img class="textLink_icon" :src="`${cdn}/img/top/icon_tab_red.svg`" alt="新しいタブ">
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped land="scss">
.article:last-child {
  padding-bottom: 50px;
}

.titleAreaImg {
  display: block;
  width: 100%;
}

.titleAreaImg-sp {
  display: none;
}

@media screen and (max-width: 600px) {
  .titleAreaImg-pc {
    display: none;
  }

  .titleAreaImg-sp {
    display: block;
  }
}

.spmApp {
  text-align: center;
}

.spmApp_img {
  height: 200px;
}

.titleTerm {
  text-align: center;
  padding: 10px 20px;
  background-color: var(--col-grey);
}

.article_decoratedTextLinkRow {
  padding-top: 30px;
  padding-bottom: 10px;
  text-align: center;
  margin-bottom: 30px;
}

.article_decoratedTextLinkRow .article_listArea,
.article_list-dot .article_listArea {
  margin-top: 10px;
  margin-bottom: 10px;
}

.article_imgList-arrowBig {
  font-size: 50px;
}

.article_imgListArea-condition .article_imgList-three {
  background-color: var(--col-grey);
  padding: 35px 25px;
  border-radius: 20px;
}

.article_imgList-three {
  flex-basis: 40%;
}

.article_imgListText {
  text-align: left;
}

.textCenterArea {
  text-align: center;
}

.bgColorArea {
  background-color: #f5f1ea;
  margin: 40px 0;
  padding: 40px 20px 20px;
}

.article_heading3-topMargin {
  margin-top: 50px;
}

.textLink {
  color: var(--col-main);
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
}

.textLink_icon {
  margin-left: 10px;
}
</style>

<script>
import AppStore from '../../components/AppStore'
import { updateMetaHead } from '../../lib/utils'

export default {
  props:{
  },

  data: function() {
    return {
      cdn: CDN,
    }
  },

  mounted: function() {
    updateMetaHead('総額30万Pontaポイントを山分けしてプレゼント',
      '期間中に、STOCKPOINT for MUFGを新規でインストールして頂き、Ponta会員IDを連携のうえ1ポイント以上チャージしていただくと、総額30万Pontaポイントを山分けしてプレゼント！')
  },

  methods: {
  },

  components: {
    AppStore
  }
}
</script>
