<template>
  <div>
    <div class="header">
      <router-link :to="{ name: 'Index' }">
        <img :src="`${cdn}/img/top/logo_spm.webp`" width="209" height="15" alt="STOCKPOINT for MUFG">
      </router-link>
    </div>
    <div class="titleArea">
      <Breadcrumb />
      <h1 class="titleArea_title">提携ポイント・交換レート</h1>
      <div class="titleArea_subTitle">
        提携先のポイントをStockPointへ交換すれば、すぐにポイント運用をスタートできます。
      </div>
    </div>

    <div class="partnersArea">
      <div class="partners">
        <div class="cardArea">
          <input class="partners_checkbox" id="partner01" name="partner01" type="checkbox">
          <div class="card">
            <div class="card_front">
              <img class="card_logo" :src="`${cdn}/img/top/logo_ponta.webp`" width="198" height="75" alt="Ponta">
              <div>Pontaポイント</div>
              <label class="card_button" for="partner01" tabIndex="0">
                交換比率はこちら
                <img class="button_icon" :src="`${cdn}/img/top/icon_exchange_main.webp`" width="17" height="14" alt="回転">
              </label>
            </div>
            <div class="card_back">
              <div v-if="'2024/07/01 00:00:00' <= getServerDateString(NOW_DATE)" class="card_text">
                <p>・月内合計 1~100ポイントまで</p>
                <span class="before">1<span class="unit">Pontaポイント</span></span>
                →
                <span class="after">1<span class="unit">キャッシュポイント</span></span>
                <p>・月内合計 101ポイント以降</p>
                <span class="before">1<span class="unit">Pontaポイント</span></span>
                →
                <span class="after">0.8<span class="unit">キャッシュポイント</span></span>
              </div>
              <div v-else class="card_text">
                <span class="before">1<span class="unit">Pontaポイント</span></span>
                →
                <span class="after">1<span class="unit">キャッシュポイント</span></span>
              </div>
              <div class="card_text">
                <span class="before">1<span class="unit">キャッシュポイント</span></span>
                →
                <span class="after">1<span class="unit">Pontaポイント</span></span>
              </div>
              <label class="card_button" for="partner01" tabIndex="0">
                ロゴ表示
                <img class="button_icon" :src="`${cdn}/img/top/icon_exchange.webp`" width="17" height="14" alt="回転">
              </label>
            </div>
          </div>
        </div>

        <div class="cardArea">
          <input class="partners_checkbox" id="partner02" name="partner02" type="checkbox">
          <div class="card">
            <div class="card_front">
              <img class="card_logo" :src="`${cdn}/img/top/logo_mufg.jpg`" width="335" height="60" alt="MUFG 三菱UFJニコス">
              <div>グローバルポイント</div>
              <label class="card_button" for="partner02" tabIndex="0">
                交換比率はこちら
                <img class="button_icon" :src="`${cdn}/img/top/icon_exchange_main.webp`" width="17" height="14" alt="回転">
              </label>
            </div>
            <div class="card_back">
              <div class="card_text">
                <span class="before">1<span class="unit">グローバルポイント</span></span>
                →
                <span class="after">4<span class="unit">キャッシュポイント</span></span>
              </div>
              <label class="card_button" for="partner02" tabIndex="0">
                ロゴ表示
                <img class="button_icon" :src="`${cdn}/img/top/icon_exchange.webp`" width="17" height="14" alt="回転">
              </label>
            </div>
          </div>
        </div>

        <div v-if="'2023/02/16 00:00:00' <= getServerDateString(NOW_DATE)" class="cardArea">
          <input class="partners_checkbox" id="partner03" name="partner03" type="checkbox">
          <div class="card">
            <div class="card_front">
              <img class="card_logo" :src="`${cdn}/img/top/jaccs.jpg`" width="199" height="80" alt="JACCS">
              <div>ラブリィポイント</div>
              <label class="card_button" for="partner03" tabIndex="0">
                交換比率はこちら
                <img class="button_icon" :src="`${cdn}/img/top/icon_exchange_main.webp`" width="17" height="14" alt="回転">
              </label>
            </div>
            <div class="card_back">
              <div class="card_text">
                <span class="before">1<span class="unit">ラブリィポイント</span></span>
                →
                <span class="after">1<span class="unit">キャッシュポイント</span></span>
              </div>
              <label class="card_button" for="partner03" tabIndex="0">
                ロゴ表示
                <img class="button_icon" :src="`${cdn}/img/top/icon_exchange.webp`" width="17" height="14" alt="回転">
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="partnersFooter">
      <div class="bubbleArea">
        <img :src="`${cdn}/img/top/img_fairy_bubbles.webp`" width="316" height="292" alt="吹き出し">
        <span class="bubble">提携ポイントは順次拡大予定です</span>
      </div>
      <BorderLink :routeName="'Feature'" :text="'アプリの特長へもどる'" />
    </div>
  </div>
</template>

<style scoped>
.partnersArea {
  background-color: #f5f1ea;
  padding: 30px 0 10px 20px;
}

.partners {
	display: flex;
	flex-wrap: wrap;
  justify-content: center;
}

.partners_checkbox {
  display: none;
}

.card {
	width: 335px;
	min-height: 194px;
	border-radius: 20px;
	box-shadow: 0 2px 6px #e2d6c3;
	transition: transform 0.5s;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
	transform: rotateY(0deg);
  margin-right: 20px;
  margin-bottom: 20px;
}

.partners_checkbox:checked ~ .card {
	transform: rotateY(180deg);
}

@media screen and (max-width: 375px) {
  .partners {
    flex-direction: column;
  }

  .cardArea {
    width: calc(100% - 20px);
  }

  .card {
    width: 100%;
  }
}

.card_front,
.card_back {
	position: absolute;
	width: 100%;
	height: 100%;
	padding: 0;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	border-radius: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	transition: z-index 0.5s;
  overflow: hidden;
  text-align: center;
}

.card_front {
	background: white;
	z-index: 2;
}

.card_front-soon {
	justify-content: center;
}

.card_back {
	background: #FDF6F7;
	transform: rotateY(180deg);
	z-index: 1;
}

.card_back p {
  font-size: 12px;
  line-height: 1.2;
}

.partners_checkbox:checked ~ .card .card_front {
	z-index: 1;
}

.partners_checkbox:checked ~ .card .card_back {
	z-index: 2;
}

.card_logo {
  margin-top: 25px;
  max-height: 80px;
}

.card_button {
  width: 100%;
  padding: 10px 20px;
  line-height: 1;
}

.card_button:hover,
.card_button:focus {
  text-decoration: underline;
}

.card_front .card_button {
	background: #FDF6F7;
  color: var(--col-main);
}

.card_back .card_button {
	background: white;
	color: var(--col-black);
}

.button_icon {
  margin-left: 10px;
}

.card_text {
  text-align: left;
  width: 270px;
  max-width: calc(100% - 40px);
}

.card_text:first-child {
  margin-top: 15px;
}

.before,
.after {
	font-size: 25px;
}

.unit {
	font-size: 11px;
	font-weight: normal;
  white-space: nowrap;
}

.after,
.after .unit {
	color: var(--col-main);
}

.partnersFooter {
  padding: 40px 20px 0;
}

.bubbleArea {
  text-align: center;
}

.bubble {
  position: absolute;
  top: 32px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
  font-size: 17px;
  color: #fff;
  text-shadow: 0 2px 0 #429b27;
  white-space: nowrap;
}
</style>

<script>
import BorderLink from '../components/BorderLink'
import Breadcrumb from '../components/Breadcrumb'
import { getServerDateString } from '../lib/dateModules'

export default {
  props:{
  },

  data: function() {
    return {
      cdn: CDN,
      NOW_DATE: NOW_DATE,
    }
  },

  mounted: async function() {
  },

  methods: {
    getServerDateString: getServerDateString,
  },

  components: {
    BorderLink,
    Breadcrumb
  }
}
</script>
