<template>
  <div>
    <div class="splash">
      <div class="splashTop">
        <div class="splashTop_img">
          <img :src="`${cdn}/img/top/img_splash.webp`" width="375" height="450" alt="">
        </div>
        <div class="splashTop_screenShot">
          <img :src="`${cdn}/img/top/img_screen_shot.webp`" width="199" height="272" alt="">
        </div>
      </div>
      <div class="splashBottom">
        <div class="splashBottom_title">
          <img :src="`${cdn}/img/top/title_invest_point.webp`" width="134" height="14" alt="ポイント運用">
        </div>
        <div class="splashBottom_logo">
          <img :src="`${cdn}/img/top/logo_spm3.webp`" width="280" height="77" alt="STOCKPOINT for MUFG">
        </div>
        <AppStore />
      </div>
    </div>

    <UrgentNotice />
    <CampaignBanner />
    <ImportantNotice />

    <div class="conceptArea">
      <img class="conceptArea_bg" :src="`${cdn}/img/top/img_concept_bg.webp`" width="1631" height="677" alt="">
      <div class="concept">
        <div class="concept_title">
          <img :src="`${cdn}/img/top/img_concept_title.webp`" width="335" height="449" alt="CONCEPT">
          <div class="concept_text">
            子どもから大人まで親しんだ<br>
            ロールプレイングの世界観で<br>
            楽しみながら資産運用のきっかけにつながるポイント運用アプリの登場です
          </div>
        </div>
        <Title :imgName="'title_app_feature.webp'" :width="'118'" :height="'21'" :alt="'アプリの特長'" />
        <div class="concept_heading">
          <img :src="`${cdn}/img/top/title_to_journey.webp`" width="238" height="51" alt="新しいポイント運用の旅へ一歩を踏み出そう！">
        </div>
        <div class="concept_article">
          STOCKPOINT for MUFGは、お買い物やクレジットカード利用で貯まったPontaポイント等のポイントを使い、日本株（ETF含む）、米国株、投資信託、そして暗号資産などの様々な金融商品を購入し、ポイントのまま運用体験ができるサービスです。
        </div>
        <ConceptSlide />
        <div class="concept_article">
          ポイント運用×ロールプレイングゲームという新しい体験を通して、知らず知らずのうちに、あなたの資産運用の知識と経験値も上がります！<br>
          ポイントを運用しながら、キャラクターを育てる旅を、ぜひお楽しみください。
        </div>
        <div class="rowList">
          <div class="rowList_block block">
            <img class="block_icon" :src="`${cdn}/img/top/img_invest_point.webp`" width="100" height="100" alt="">
            <div class="block_title">
              ポイントを<br>運用
            </div>
          </div>
          <div class="rowList_icon">
            <img :src="`${cdn}/img/top/icon_arrow_next_brown.webp`" width="9" height="12" alt="">
          </div>
          <div class="rowList_block">
            <img class="block_icon" :src="`${cdn}/img/top/img_train_character.webp`" width="100" height="100" alt="">
            <div class="block_title">
              キャラクター<br>育成
            </div>
          </div>
          <div class="rowList_icon">
            <img :src="`${cdn}/img/top/icon_arrow_next_brown.webp`" width="9" height="12" alt="">
          </div>
          <div class="rowList_block">
            <img class="block_icon" :src="`${cdn}/img/top/img_change_stock.webp`" width="100" height="100" alt="">
            <div class="block_title">
              ポイントを<br>株式に交換
            </div>
          </div>
        </div>
        <div class="caution">
          <img :src="`${cdn}/img/top/title_no_cash.webp`" width="243" height="47" alt="課金は一切なし　証券口座や銀行口座も不要！">
        </div>
        <div class="note">
          ※現物の金融商品とポイントを交換する場合には必要となります。
        </div>
        <DecoratedLink :routeName="'Feature'" :imgName="'title_app_feature_white.webp'" :width="'106'" :height="'19'" :alt="'アプリの特長'" />
        <div class="textLinkArea">
          <a class="textLink" href="https://spm-help.stockpoint.jp/hc/ja" target="_blank">
            よくあるご質問はこちら
            <img class="textLink_icon" :src="`${cdn}/img/top/icon_tab_red.webp`" width="14" height="14" alt="">
          </a>
        </div>
      </div>
    </div>

    <div class="storyArea">
      <Title :imgName="'title_story_and_character.webp'" :width="'237'" :height="'20'" :alt="'ストーリーとキャラクター'" />
      <div class="story_img">
        <img :src="`${cdn}/img/top/img_story.webp`" width="375" height="387" alt="ストーリー">
      </div>
      <div class="story_article">
        「銘柄オーブ」を取り戻すために──<br>騎士ソダテの旅が、今はじまる。
      </div>
      <DecoratedLink :routeName="'Story'" :imgName="'title_story_and_character_white.webp'" :width="'213'" :height="'18'" :alt="'ストーリーとキャラクター'" />
    </div>

    <div class="levelUpArea">
      <Title :imgName="'title_level_up.webp'" :width="'261'" :height="'21'" :alt="'レベルアップとクラスチェンジ'" />
      <div class="levelUp">
        <div class="levelUp_card card">
          <div class="card_title">
            <img :src="`${cdn}/img/top/title_unlock.webp`" width="263" height="19" alt="レベルアップで銘柄がアンロック">
          </div>
          <div class="card_img">
            <img :src="`${cdn}/img/top/img_orb.webp`" width="335" height="164" alt="">
          </div>
          <div class="card_heading">
            運用できる銘柄は200種類以上！
          </div>
          <div class="card_text">
            アプリ内で選択したキャラクターは、日々 EXP （経験値）を獲得することでレベルが段階的に上がります。レベルに応じて運用できる銘柄や商品が増えていくので、楽しんでいるうちにいつのまにかポイント運用の達人に。
          </div>
          <DecoratedLink :routeName="'Levelup'" :imgName="'title_level_up_white.webp'" :width="'223'" :height="'19'" :alt="'レベルアップとクラスチェンジ'" />
        </div>
      </div>
    </div>

    <div class="newsArea" v-if="newsList.length > 0">
      <Title :imgName="'title_news.webp'" :width="'78'" :height="'20'" :alt="'お知らせ'" />
      <NewsList :newsList="getFilterList(newsList)" />
      <DecoratedLink :routeName="'News'" :imgName="'title_news_list.webp'" :width="'106'" :height="'19'" :alt="'お知らせ一覧'" />
    </div>

    <div class="downloadArea">
      <Download />
    </div>

    <div class="support">
      <div class="support_title">
        お客様サポート
      </div>
      <div>
        <a class="support_link faq" href="https://spm-help.stockpoint.jp/hc/ja" target="_blank">
          <div class="faq_img">
            <img :src="`${cdn}/img/top/icon_faq.webp`" width="78" height="67" alt="FAQ">
            <img class="img_icon" :src="`${cdn}/img/top/img_fairy.webp`" width="66" height="71" alt="">
          </div>
          <div class="faq_text">
            よくあるご質問
            <img class="text_icon" :src="`${cdn}/img/top/icon_tab_brown.webp`" width="14" height="14" alt="">
          </div>
        </a>
        <a class="support_link form" href="https://spm-help.stockpoint.jp/hc/ja/requests/new" target="_blank">
          <div class="form_text">
            お問い合わせフォーム
            <img class="text_icon" :src="`${cdn}/img/top/icon_tab_brown.webp`" width="14" height="14" alt="">
          </div>
        </a>
      </div>
    </div>

    <div class="topSNS">
      <div class="topSNS_img">
        <img :src="`${cdn}/img/top/img_spm.webp`" width="375" height="425" alt="">
        <img class="img_logo" :src="`${cdn}/img/top/logo_spm3.webp`" width="280" height="77" alt="STOCKPOINT for MUFG">
      </div>
      <SNS />
    </div>
  </div>
</template>

<style scoped>
.splash {
  background-color: var(--col-grey);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.splashTop {
  height: 450px;
}

@media screen and (max-width: 395px) {
  .splashTop {
    overflow: hidden;
  }
}

.splashTop_screenShot {
  position: absolute;
  top: 97px;
  right: -20px;
  z-index: 1;
}

.splashBottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  margin-top: -80px;
  padding-bottom: 30px;
}

.splashBottom_title {
  display: inline-block;
  text-align: center;
  padding: 0 18px 10px;
  border-bottom: 3px solid var(--col-main);
  margin-bottom: 20px;
}

.splashBottom_logo {
  display: inline-block;
  margin-bottom: 30px;
}

.conceptArea {
  margin-bottom: 30px;
  overflow: hidden;
}

.conceptArea_bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  height: 677px;
  max-width: initial;
}

.concept {
  z-index: 2;
  padding-top: 20px;
  text-align: center;
}

.concept_title {
  display: inline-block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 30px;
}

.concept_text {
  position: absolute;
  top: 238px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 250px;
  line-height: 32px;
}

.concept_heading {
  margin-bottom: 30px;
}

.concept_article {
  line-height: 32px;
  padding: 0 20px;
  margin-bottom: 40px;
  text-align: left;
}

.rowList {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 20px;
}

.rowList_icon {
  margin-top: -41px;
  margin-left: 3px;
  margin-right: 3px;
}

.block_icon {
  margin-bottom: 10px;
}

.block_title {
  font-size: 12px;
  line-height: 16px;
}

.caution {
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 20px;
  border: 2px solid var(--col-main);
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.note {
  margin-left: 20px;
  margin-right: 20px;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 30px;
}

.textLinkArea {
  text-align: center;
  margin-bottom: 20px;
}

.textLink {
  color: var(--col-main);
  display: inline-flex;
  flex-wrap: nowrap;
  align-items: center;
}

.textLink_icon {
  margin-left: 10px;
}

.storyArea {
  margin-bottom: 20px;
}

.story_img {
  margin-top: -63px;
  margin-bottom: 30px;
  text-align: center;
}

.story_article {
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  padding: 0 20px;
  margin-bottom: 30px;
}

.levelUpArea {
  margin-bottom: 20px;
}

.levelUp {
  margin-top: -63px;
  background-color: #f5f1ea;
  padding: 42px 20px 20px;
}

.card {
  border-radius: 20px;
  background-color: #fff;
  overflow: hidden;
}

.card_title {
  background-color: var(--col-black);
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-bottom: 4px solid;
  border-image: linear-gradient(to right, #F6DC43, #A25C17) 1;
}

.card_img {
  margin-bottom: 25px;
  text-align: center;
}

.card_heading {
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 18px;
  color: var(--col-main);
}

.card_text {
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
  line-height: 32px;
  margin-bottom: 25px;
}

.newsArea {
  padding: 30px 20px 50px;
}

.newsArea .decoratedLinkRow {
  padding-left: 0;
  padding-right: 0;
}

.downloadArea {
	background: linear-gradient(to bottom, #efe9de, #fffef7);
}

.support {
  padding: 50px 20px 20px;
  text-align: center;
}

.support_title {
  font-size: 28px;
  text-align: center;
  margin-bottom: 30px;
}

.support_link {
  display: block;
  padding: 20px;
  text-align: center;
  background-color: var(--col-brown-light);
  border: 2px solid var(--col-brown-dark);
  border-radius: 20px;
  margin-bottom: 20px;
}

.support_link:last-child {
  margin-bottom: 40px;
}

.faq_img {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.img_icon {
  margin-left: 5px;
}

.faq_text,
.form_text {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}

.text_icon {
  margin-left: 5px;
}

.topSNS {
  padding-bottom: 20px;
}

.topSNS_img {
  text-align: center;
  margin-bottom: 20px;
}

.img_logo {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
}
</style>

<script>
import UrgentNotice from '../components/UrgentNotice'
import CampaignBanner from '../components/CampaignBanner'
import ImportantNotice from '../components/ImportantNotice'
import Title from '../components/Title'
import DecoratedLink from '../components/DecoratedLink'
import NewsList from '../components/NewsList'
import Download from '../components/Download'
import SNS from '../components/SNS'
import AppStore from '../components/AppStore'
import ConceptSlide from '../components/ConceptSlide'
import { getList } from '../lib/listModules'
import { getServerDateString } from '../lib/dateModules'

export default {
  props:{
  },

  data: function() {
    return {
      cdn: CDN,
      newsList: [],
      MAX_NEWS_LIST: 3
    }
  },

  mounted: async function() {
    this.newsList = await getList('news.json')
  },

  methods: {
    getFilterList: function(list) {
      const serverDate = getServerDateString(NOW_DATE)
      const listExceptedFuture = list.filter(
        (news) => !news.releaseDate || news.releaseDate <= serverDate
      )
      return listExceptedFuture.slice(0, this.MAX_NEWS_LIST)
    },

  },

  components: {
    UrgentNotice,
    CampaignBanner,
    ImportantNotice,
    Title,
    DecoratedLink,
    NewsList,
    Download,
    SNS,
    AppStore,
    ConceptSlide
  }
}
</script>
