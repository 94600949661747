<template>
  <div v-if="urgentNoticeList.length > 0">
    <div
      class="noticeRow"
      v-for="(urgentNotice, index) in urgentNoticeList"
      :key="index"
    >
      <a class="notice" :href="urgentNotice.url" :target="urgentNotice.isNewTab ? '_blank' : ''">
        <span class="notice_title">{{ urgentNotice.title }}</span>
        <img class="notice_icon" :src="`${cdn}/img/top/icon_arrow_right.webp`" width="7" height="11" alt="">
      </a>
    </div>
  </div>
</template>

<style scoped>
.noticeRow {
  padding: 0 20px;
  margin-bottom: 30px;
}

.notice {
  border: 2px solid var(--col-main);
  border-radius: 20px;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  white-space: initial;
}

.notice_title {
  color: var(--col-main);
}

.notice:hover .notice_title {
  text-decoration: underline;
}

.notice_icon {
  margin-left: 10px;
}
</style>

<script>
import { getList } from '../lib/listModules'

export default {
  props:{
  },

  data: function() {
    return {
      cdn: CDN,
      urgentNoticeList: []
    }
  },

  mounted: async function() {
    this.urgentNoticeList = await getList('urgentNotice.json')
  },

  methods: {
  },

  components: {
  }
}
</script>
