<template>
  <div class="contents breadcrumbArea">
    <div class="breadcrumbArea_row">
      <div
        v-for="breadcrumb in getBreadcrumbList()"
        :key="breadcrumb.name"
        class="breadcrumb"
      >
        <span
          v-if="breadcrumb.text != 'トップ'"
          class="breadcrumb_text breadcrumb_text-icon"
        >></span>
        <span
          v-if="isNow(breadcrumb.name)"
          class="breadcrumb_text breadcrumb_text-now"
        >{{ breadcrumb.text }}</span>
        <router-link
          v-else
          class="breadcrumb_text"
          :to="{ name: breadcrumb.name }"
        >{{ breadcrumb.text }}</router-link>
      </div>
    </div>
  </div>
</template>

<style scoped>
.breadcrumbArea {
  display: flex;
  padding: 15px 20px 0;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -10px;
}

.breadcrumbArea_row {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
}

.breadcrumb {
  display: inline-flex;
  align-items: center;
  padding-right: 10px;
  padding-bottom: 10px;
}

.breadcrumb_text {
  font-size: 14px;
  line-height: 27px;
}

.breadcrumb_text-icon {
  margin-right: 10px;
}

.breadcrumb_text-now {
  opacity: 0.5;
}
</style>

<script>
export default {
  props:{
    routeName: { type: String, default: "" },
    text: { type: String, default: "" }
  },

  data: function() {
    return {
      cdn: CDN
    }
  },

  mounted: function() {
  },

  methods: {
    getBreadcrumbList: function() {
      const breadcrumbList = []
      const pathList = this.$route.path.split('/')
      for (let i = 1; i < pathList.length; i++) {
        if (pathList[i] != '') {
          pathList[i] = `${pathList[i - 1]}/${pathList[i]}`
        }
      }
      pathList[0] = '/'

      pathList.forEach((path) => {
        if (path != '') {
          if (this.$router.options.routes != null) {
            const route = this.$router.options.routes.find(
              (route) => route.path == path
            )
            if (route != null) {
              breadcrumbList.push({
                name: route.name == null ? '' : route.name,
                text: route.meta.title
              })
            }
          } else {
            breadcrumbList.push({ name: '', text: '' })
          }
        }
      })
      return breadcrumbList
    },

    isNow: function(name) {
      return this.$route.name == name
    }
  },

  components: {
  }
}
</script>
