<template>
  <div>
    <div class="header">
      <router-link :to="{ name: 'Index' }">
        <img :src="`${cdn}/img/top/logo_spm.webp`" width="209" height="15" alt="STOCKPOINT for MUFG">
      </router-link>
    </div>
    <Breadcrumb />
    <div class="charaArea_wrapper">
      <button :class="['listButton listButton-prev', { 'listButton-disabled': $route.meta.charaNo == 0 }]" @click="plusCharaNo(-1)">
        <img class="listButton_img" :src="`${cdn}/img/top/icon_arrow_left_white.webp`" width="7" height="11" alt="前へ">
      </button>
      <button :class="['listButton listButton-next', { 'listButton-disabled': $route.meta.charaNo == CHARA_NUM - 1 }]" @click="plusCharaNo(1)">
        <img class="listButton_img" :src="`${cdn}/img/top/icon_arrow_right_white.webp`" width="7" height="11" alt="次へ">
      </button>
      <div class="charaArea" :style="getCharaAreaStyle(0)">
        <div class="titleArea">
          <h1 class="titleArea_title">騎士ソダテ</h1>
          <div class="titleArea_subTitle">
            由緒正しきカブ家の若き騎士
          </div>
        </div>
        <div>
          <div class="characterArea">
            <div>
              <div class="character_bg">
                <img :src="`${cdn}/img/top/img_character_bg.webp`" width="375" height="310" alt="">
              </div>
              <img class="character_img" :src="`${cdn}/img/top/img_character.webp`" width="200" height="332" alt="">
            </div>
            <div class="character_heading">
              由緒正しきカブ家の若き騎士
            </div>
            <Title2 :alt="'ソダテ'" />
            <div class="character_article">
              失われた伝説のカブ剣を操り、銘柄を選ぶ鋭さは天下一品。カブの兜がトレードマーク。<br>
              剣技ETF（エレクトロタイタニックフォース）の使い手。経験を積むことで、立派に成長していく。
            </div>
          </div>
        </div>

        <div>
          <Title :alt="'STORY'" />
          <div class="storyArea">
            <div class="story_img">
              <img :src="`${cdn}/img/top/img_story_bg.webp`" width="375" height="360" alt="">
            </div>
            <div class="story_article">
              ここは自由経済によって活気あふれる王国。<br>
              人々は株式運用をはじめ、多くの取引によって発展を謳歌していた。
            </div>
            <div class="story_img">
              <img :src="`${cdn}/img/top/img_sodate_story_01.webp`" width="375" height="330" alt="">
            </div>
            <div class="story_article">
              ある日、自由を嫌う魔王が、恐ろしいモンスター共を従え王国を侵略。<br>
              一夜にして王国の宝である、数百個の「銘柄オーブ」が奪われてしまったのだった。
            </div>
            <div class="story_img">
              <img :src="`${cdn}/img/top/img_sodate_story_02.webp`" width="375" height="294" alt="">
            </div>
            <div class="story_article">
              ここは王国の中央から離れた辺境の自治領「カブ」。立国の英雄カブ家の末裔にして、若くして騎士団長となった「ソダテ」。
            </div>
            <div class="story_img">
              <img :src="`${cdn}/img/top/img_sodate_story_03.webp`" width="375" height="492" alt="">
            </div>
            <div class="story_article">
              モンスターに奪われた「銘柄オーブ」を取り戻すために──ソダテの旅が、今はじまる。
            </div>
          </div>
        </div>
      </div>

      <div class="charaArea" :style="getCharaAreaStyle(1)">
        <div class="titleArea">
          <h1 class="titleArea_title">魔法使いマナビ</h1>
          <div class="titleArea_subTitle">
            市場の声が聞こえる魔法使い
          </div>
        </div>
        <div>
          <div class="characterArea">
            <div>
              <div class="character_bg">
                <img :src="`${cdn}/img/top/img_manabi_bg.webp`" width="375" height="310" alt="">
              </div>
              <img class="character_img" :src="`${cdn}/img/top/img_manabi.webp`" width="178" height="315" alt="キャラクター">
            </div>
            <div class="character_heading">
              市場の声が聞こえる魔法使い
            </div>
            <Title2 :alt="'マナビ'" />
            <div class="character_article">
              投資の神託を受けた若き少年魔導士。<br>
              読書好きで博識だが、突然の変化に慌てやすい。丸メガネがトレードマーク。<br>
              立派な魔導士を目指し、勉学の旅の途中、<br>
              ソダテに出会い行動を共にする。
            </div>
          </div>
        </div>

        <div>
          <Title :alt="'STORY'" />
          <div class="storyArea">
            <div class="story_img">
              <img :src="`${cdn}/img/top/img_manabi_story_01.webp`" width="375" height="330" alt="">
            </div>
            <div class="story_article">
              魔王軍が王国の魔術学院を襲い「銘柄オーブ」が奪われてからおおよそ一年の月日が流れた。<br>
              <br>
              王国の魔術学院で学んでいた魔術師の「マナビ」はファンド師の遺言に従い「銘柄オーブ」を探す旅に出る。
            </div>
            <div class="story_img">
              <img :src="`${cdn}/img/top/img_manabi_story_02.webp`" width="375" height="373" alt="">
            </div>
            <div class="story_article">
              旅の道中で耳にした騎士「ソダテ」の足取りを追うマナビ。魔王と戦う彼女の窮地を、間一髪でマナビが救う。
            </div>
            <div class="story_img">
              <img :src="`${cdn}/img/top/img_manabi_story_03.webp`" width="375" height="375" alt="">
            </div>
            <div class="story_article">
              今のままでは魔王に勝てない。魔王を打ち破る手がかりを探すため、マナビとソダテの2人は共に新天地を目指すのであった。
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <BorderLink :routeName="'Story'" :text="'ストーリーとキャラクター'" />
    </div>
    <div class="snsArea">
      <SNS />
    </div>
  </div>
</template>

<style scoped>
.charaArea_wrapper {
  overflow-x: hidden;
}

.listButton {
  position: absolute;
  top: 440px;
  z-index: 2;
  background-color: var(--col-main);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.listButton-disabled {
  background-color: #cbcbcb;
  pointer-events: none;
}

.listButton-disabled .listButton_img {
  opacity: .5;
}

.listButton-prev {
  left: 10px;
}

.listButton-next {
  right: 10px;
}

.charaArea {
  width: 100%;
  top: 0;
  transition: left 0.3s;
}

.characterArea {
  background-color: #f5f1ea;
  padding-bottom: 10px;
  margin-bottom: 40px;
}

.character_bg {
  padding-bottom: 75px;
  text-align: center;
}

.character_img {
  position: absolute;
  top: 32px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
}

.character_heading {
  text-align: center;
  padding-bottom: 15px;
}

.character_article {
  font-size: 18px;
  line-height: 32px;
  padding: 0 20px;
  padding-bottom: 30px;
}

.storyArea {
  margin-top: -60px;
}

.story_article {
  font-size: 18px;
  line-height: 32px;
  padding: 0 20px;
  padding-bottom: 30px;
}

.story_img {
  text-align: center;
  padding-bottom: 30px;
}

.snsArea {
  padding-top: 40px;
  padding-bottom: 30px;
}
</style>

<script>
import SNS from '../components/SNS'
import Title from '../components/Title'
import Title2 from '../components/Title2'
import BorderLink from '../components/BorderLink'
import Breadcrumb from '../components/Breadcrumb'

const CHARA_LSIT = ['Sodate','Manabi']
export default {
  props:{
  },

  data: function() {
    return {
      cdn: CDN,
      CHARA_NUM: CHARA_LSIT.length
    }
  },

  mounted: function() {
  },

  methods: {
    plusCharaNo: function(plusNo) {
      const newNo = this.$route.meta.charaNo + plusNo
      if (0 <= newNo && newNo < CHARA_LSIT.length) {
        this.$router.replace({ name: CHARA_LSIT[newNo] })
      }
    },

    getCharaAreaStyle: function(areaNo) {
      return {
        left: `${100 * (areaNo - this.$route.meta.charaNo)}%`,
        position: this.$route.meta.charaNo == areaNo ? 'relative' : 'absolute'
      }
    }
  },

  components: {
    SNS,
    Title,
    Title2,
    BorderLink,
    Breadcrumb
  }
}
</script>
