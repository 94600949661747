<template>
  <div class="borderLinkRow">
    <router-link class="borderLink" :to="{ name: routeName }">
      <img class="borderLink_icon" :src="`${cdn}/img/top/icon_arrow_left.webp`" width="7" height="11" alt="戻る">
      {{ text }}
    </router-link>
  </div>
</template>

<style scoped>
.borderLinkRow {
  padding-top: 30px;
  padding-bottom: 40px;
  text-align: center;
}

.borderLink {
  display: flex;
  max-width: 335px;
  height: 50px;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--col-main);
  border-radius: 25px;
  padding: 17px 18px;
  margin-left: auto;
  margin-right: auto;
  color: var(--col-main);
}

.borderLink_icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 17px;
  margin: auto;
  z-index: 1;
}
</style>

<script>
export default {
  props:{
    routeName: { type: String, default: "" },
    text: { type: String, default: "" }
  },

  data: function() {
    return {
      cdn: CDN
    }
  },

  mounted: async function() {
  },

  methods: {
  },

  components: {
  }
}
</script>
