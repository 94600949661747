<template>
  <div class="newsList">
    <div class="notFound" v-if="newsList.length == 0">
      該当するお知らせがありません。
    </div>
    <div v-else v-for="(news, index) in newsList" :key="news.yymmdd_nn">
      <h2 class="article_heading2" v-if="isDispYear && (index == 0 || newsList[index - 1].yymmdd_nn.slice(0, 2) != news.yymmdd_nn.slice(0, 2))">
        20{{ news.yymmdd_nn.slice(0, 2) }}年
      </h2>
      <a
        :href="news.url"
        :target="news.isNewTab ? '_blank' : ''"
        :class="['news', news.url == null || news.url == '' ? 'noLink' : '']"
      >
        <div class="news_dateCategory">
          <div class="date">{{ getDotDate(news.yymmdd_nn) }}</div>
          <span :class="['category', { 'category-important': news.category == '重要なお知らせ' }]">
            {{ news.category }}
          </span>
        </div>
        <div class="news_title">
          {{ news.title }}
        </div>
      </a>
    </div>
  </div>
</template>

<style scoped>
.newsList {
  padding-bottom: 30px;
}

.article_heading2 {
  padding-top: 40px;
  margin-bottom: 0;
}

.news {
  display: block;
  padding: 20px 0;
  border-bottom: 1px solid var(--col-black50);
}

.news:hover,
.news:focus {
  text-decoration: none;
}

.noLink {
  pointer-events: none;
}

.news_dateCategory {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-bottom: 15px;
}

.date {
  font-size: 14px;
  color: #808080;
  margin-right: 20px;
}

.category {
  background-color: var(--col-grey);
  color: #a09a9c;
  font-size: 12px;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 20px;
  line-height: 1;
}

.category-important {
  background-color: #ffebed;
  color: var(--col-main);
}

.news_title {
  white-space: initial;
}

.news:hover .news_title,
.news:focus .news_title {
  text-decoration: underline;
}
</style>


<script>
import { getDotDate } from '../lib/dateModules'

export default {
  props:{
    newsList: { type: Array, default: [] },
    isDispYear: { type: Boolean, default: false },
    category: { type: String, default: '' },
  },

  data: function() {
    return {
      cdn: CDN
    }
  },

  mounted: async function() {
  },

  methods: {
    getDotDate: getDotDate
  },

  components: {
  }
}
</script>
