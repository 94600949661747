import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import Campaign from '../views/Campaign.vue'
import CampaignDetail from '../views/CampaignDetail.vue'
import LPPonta from '../views/LP/Ponta.vue'
import Feature from '../views/Feature.vue'
import Partners from '../views/Partners.vue'
import Brands from '../views/Brands.vue'
import Story from '../views/Story.vue'
import Chara from '../views/Chara.vue'
import Levelup from '../views/Levelup.vue'
import Rule from '../views/Rule.vue'
import News from '../views/News.vue'
import NewsDetail from '../views/NewsDetail.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
    name: 'Index',
    meta: {
      title: 'トップ'
    },
    component: Index,
	},
	{
		path: '/index',
    name: 'Index2',
    meta: {
      title: 'トップ'
    },
    component: Index,
	},
	{
		path: '/top/campaign',
    name: 'Campaign',
    meta: {
      title: 'キャンペーン一覧'
    },
    component: Campaign,
	},
	{
		path: '/top/lp/ponta',
    name: 'LPPonta',
    meta: {
      noTemplate: true
    },
    component: LPPonta,
	},
	{
		path: '/top/campaign/:yymmdd_nn',
    name: 'CampaignDetail',
    meta: {
      title: 'キャンペーン詳細'
    },
    component: CampaignDetail,
	},
	{
		path: '/top/feature',
    name: 'Feature',
    meta: {
      title: 'アプリの特長'
    },
    component: Feature,
	},
	{
		path: '/top/feature/partners',
    name: 'Partners',
    meta: {
      title: '提携ポイント・交換レート'
    },
    component: Partners,
	},
	{
		path: '/top/feature/brands',
    name: 'Brands',
    meta: {
      title: '銘柄ラインアップ'
    },
    component: Brands,
	},
	{
		path: '/top/story',
    name: 'Story',
    meta: {
      title: 'ストーリーとキャラクター'
    },
    component: Story,
	},
	{
		path: '/top/story/sodate',
    name: 'Sodate',
    meta: {
      title: '騎士ソダテ',
      charaNo: 0
    },
    component: Chara,
	},
	{
		path: '/top/story/manabi',
    name: 'Manabi',
    meta: {
      title: '魔法使いマナビ',
      charaNo: 1
    },
    component: Chara,
	},
	{
		path: '/top/levelup',
    name: 'Levelup',
    meta: {
      title: 'レベルアップとクラスチェンジ'
    },
    component: Levelup,
	},
	{
		path: '/top/rule',
    name: 'Rule',
    meta: {
      title: 'ゲームルール'
    },
    component: Rule,
	},
	{
		path: '/top/news',
    name: 'News',
    meta: {
      title: 'お知らせ'
    },
    component: News,
	},
	{
		path: '/top/news/:yymmdd_nn',
    name: 'NewsDetail',
    meta: {
      title: 'お知らせ詳細'
    },
    component: NewsDetail,
	},
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ selector: to.hash, behavior: 'smooth' })
        }, 1000)
      })
    } else {
      return { x: 0, y: 0 }
    }
  }
})

export default router

