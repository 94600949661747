<template>
  <div class="campaignList" v-if="campaignList.length > 0">
    <div class="campaignCaution" v-if="navStatus == 'end'"
    ><span class="campaignCaution_icon">
      <img :src="`${cdn}/img/top/icon_exclamation.webp`" width="3" height="12" alt="！">
    </span>以下のキャンペーンは終了しています</div>

    <a
      v-for="(campaign, index) in campaignList"
      :key="index"
      :href="campaign.url"
      :target="campaign.isNewTab ? '_blank' : ''"
      :class="['campaign', campaign.url == null || campaign.url == '' ? 'noLink' : '']"
    >
      <div class="campaign_icon">
        <img :src="`/static/campaign/img/${campaign.imgName}`" width="120" height="120" :alt="campaign.alt">
      </div>
      <div>
        <div class="campaign_tag" v-if="navStatus == 'end'">
          <span class="tag">
            終了
          </span>
        </div>
        <div class="campaign_title" v-if="campaign.title !== ''">{{ campaign.title }}</div>
        <div class="campaign_subTitle" v-if="campaign.subTitle">
          {{ campaign.subTitle }}
        </div>
        <div class="campaign_date" v-if="campaign.startYYMMDD !== '' && campaign.endYYMMDD !== ''">
          期間：{{ getJapaneseDate(campaign.startYYMMDD) }}〜{{ getJapaneseDate(campaign.endYYMMDD) }}まで
        </div>
        <div class="campaign_date" v-else-if="campaign.startYYMMDD !== ''">
          期間：{{ getJapaneseDate(campaign.startYYMMDD) }}〜
        </div>
        <div class="campaign_date" v-else-if="campaign.endYYMMDD !== ''">
          期間：{{ getJapaneseDate(campaign.endYYMMDD) }}まで
        </div>
        <div class="campaign_caution" v-if="campaign.caution">
          {{ campaign.caution }}
        </div>
      </div>
    </a>
  </div>
  <div class="notFound" v-else>
    該当するキャンペーンがありません。
  </div>
</template>

<style scoped>
.campaignList {
  padding-bottom: 30px;
}

.campaignCaution {
  margin: 20px 20px 0;
  border-radius: 20px;
  background-color: var(--col-grey);
  padding: 20px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.campaignCaution_icon {
  width: 24px;
  height: 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--col-main);
  margin-right: 10px;
}

.campaign {
  display: flex;
  flex-wrap: nowrap;
  margin: 30px 20px 0;
  border-bottom: 1px solid var(--col-black50);
  padding-bottom: 30px;
  white-space: initial;
}

.campaign:hover,
.campaign:focus {
  text-decoration: none;
}

.noLink {
  pointer-events: none;
}

.campaign_icon {
  flex: 0 0 120px;
  height: 120px;
  margin-right: 20px;
  display: inline-flex;
  align-items: center;
}

.campaign_tag {
  margin-bottom: 10px;
}

.tag {
  color: #fff;
  font-size: 12px;
  padding: 4px 14px;
  background-color: var(--col-black);
  border-radius: 15px;
}

.campaign_title {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 15px;
}

.campaign_subTitle {
  margin-bottom: 15px;
}

.campaign_date {
  color: var(--col-main);
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 15px;
}

.campaign_caution {
  color: var(--col-main);
}

.campaign:hover .campaign_title,
.campaign:focus .campaign_title,
.campaign:hover .campaign_subTitle,
.campaign:focus .campaign_subTitle,
.campaign:hover .campaign_date,
.campaign:focus .campaign_date,
.campaign:hover .campaign_caution,
.campaign:focus .campaign_caution {
  text-decoration: underline;
}
</style>


<script>
import { getJapaneseDate } from '../lib/dateModules'

export default {
  props:{
    campaignList: { type: Array, default: [] },
    navStatus: { type: String, default: '' }
  },

  data: function() {
    return {
      cdn: CDN
    }
  },

  mounted: async function() {
  },

  methods: {
    getJapaneseDate: getJapaneseDate
  },

  components: {
  }
}
</script>
