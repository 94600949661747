<template>
  <div>
    <div class="header">
      <router-link :to="{ name: 'Index' }">
        <img :src="`${cdn}/img/top/logo_spm.webp`" width="209" height="15" alt="STOCKPOINT for MUFG">
      </router-link>
    </div>
    <div v-if="article === ''" class="loading" />
    <div v-else v-html="article"></div>
    <div class="newsFooter">
      <SNS />
      <BorderLink :routeName="'News'" :text="'お知らせ一覧へもどる'" />
    </div>
  </div>
</template>

<style scoped>
.newsFooter {
  padding: 40px 20px 0;
}
</style>

<script>
import SNS from '../components/SNS'
import BorderLink from '../components/BorderLink'
import axios from 'axios'

export default {
  props:{
  },

  data: function() {
    return {
      cdn: CDN,
      article: ''
    }
  },

  mounted: async function() {
    this.article = await this.getArticleHTML()
  },

  methods: {
    getArticleHTML: async function() {
      const res = await axios
        .get(`/static/news/${this.$route.params.yymmdd_nn}.html`)
        .then(function (res) {
          return res.data
        })
        .catch(function (e) {
          console.log(e)
          return false
        })

      if (!res || res.indexOf('ページが見つかりません。<br>恐れ入りますが最初からやり直してください。') >= 0) {
        return '<div class="notFound">ページが見つかりません。<br>恐れ入りますが最初からやり直してください。</div>'
      } else {
        return res.replace(/src="\/cdn/g, `src="${this.cdn}`)
      }
    }
  },

  components: {
    SNS,
    BorderLink
  }
}
</script>
