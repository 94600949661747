<template>
  <div class="listArea" v-if="slideList.length > 0">
    <div class="list" :style="slideTransitionX()">
      <div
        :class="['list_img', { 'list_img-now': nowSlideNo == index}]"
        v-for="(slide, index) in slideList"
        :key="index"
      >
        <img :src="`${cdn}/img/top/${slide.imgName}`" width="206" height="416" :alt="slide.alt">
      </div>
    </div>
    <button :class="['listButton listButton-prev', { 'listButton-disabled': nowSlideNo == 0 }]" @click="slidePrev()">
      <img class="listButton_img" :src="`${cdn}/img/top/icon_arrow_left_white.webp`" width="7" height="11" alt="前へ">
    </button>
    <button :class="['listButton listButton-next', { 'listButton-disabled': nowSlideNo == slideList.length - 1 }]" @click="slideNext()">
      <img class="listButton_img" :src="`${cdn}/img/top/icon_arrow_right_white.webp`" width="7" height="11" alt="次へ">
    </button>
    <div class="listNav">
      <button
        :class="['listNav_button', { 'listNav_button-now': nowSlideNo == index }]"
        v-for="(slide, index) in slideList"
        :key="index"
        :aria-label="`button${index}`"
        @click="slideToID(index)"
      ></button>
    </div>
  </div>
</template>

<style scoped>
.listArea {
  max-width: 375px;
  height: 478px;
  margin: 0 auto 40px;
  overflow: hidden;
}

.list {
  position: absolute;
  top: 0;
  left: 85px;
  z-index: 1;
  height: 447px;
  display: flex;
  flex-wrap: nowrap;
  transition: transform .3s;
  transform: translateX(var(--transition-x));
}

.list_img {
  width: 206px;
  height: 447px;
  margin-right: 30px;
  display: inline-block;
  transition: opacity .3s;
  opacity: .5;
}

.list_img-now {
  opacity: 1;
}

.listButton {
  position: absolute;
  top: 200px;
  z-index: 2;
  background-color: var(--col-main);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.listButton-disabled {
  background-color: #cbcbcb;
  pointer-events: none;
}

.listButton-disabled .listButton_img {
  opacity: .5;
}

.listButton-prev {
  left: 10px;
}

.listButton-next {
  right: 10px;
}

.listNav {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.listNav_button {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #cbcbcb;
}

.listNav_button:not(:last-child) {
  margin-right: 15px;
}

.listNav_button-now {
  background-color: var(--col-main);
}
</style>

<script>
import { getList } from '../lib/listModules'

const SLIDE_INTERVAL_MSEC = 5000
export default {
  props:{
  },

  data: function() {
    return {
      cdn: CDN,
      isAutoSlide: false,
      nowSlideNo: 0,
      slideList: [],
      timer: null,
    }
  },

  mounted: async function() {
    this.setTimer()
    this.slideList = await getList('slide.json')
  },

  methods: {
    setTimer: function() {
      if (this.isAutoSlide) {
        clearInterval(this.timer)
        this.timer = setInterval(this.slideNext, SLIDE_INTERVAL_MSEC)
      }
    },

    slidePrev: function() {
      const prevNo = this.nowSlideNo == 0 ? this.slideList.length - 1 : this.nowSlideNo - 1
      this.slideToID(prevNo)
    },

    slideNext: function() {
      const nextNo = this.nowSlideNo == this.slideList.length - 1 ? 0 : this.nowSlideNo + 1
      this.slideToID(nextNo)
    },

    slideToID: function(no) {
      if (0 <= no && no < this.slideList.length) {
        this.nowSlideNo = no
        this.setTimer()
      }
    },

    slideTransitionX: function() {
      return {
        '--transition-x': `${-236 * this.nowSlideNo}px`
      }
    }
  },

  components: {
  }
}
</script>
