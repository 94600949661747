<template>
  <div class="sns">
    <div class="sns_title">
      このページをシェア
    </div>
    <div class="sns_link">
      <a class="link" :href="`https://twitter.com/share?url=${getURL()}`" target="_blank">
        <img :src="`${cdn}/img/top/icon_x_black.webp`" width="39" height="40" alt="X">
      </a>
      <a class="link" :href="`https://www.facebook.com/share.php?u=${getURL()}`" target="_blank">
        <img :src="`${cdn}/img/top/icon_facebook.webp`" width="40" height="40" alt="Facebook">
      </a>
      <a class="link" :href="`https://social-plugins.line.me/lineit/share?url=${getURL()}`" target="_blank">
        <img :src="`${cdn}/img/top/icon_line.webp`" width="40" height="40" alt="LINE">
      </a>
    </div>
  </div>
</template>

<style scoped>
.sns_title {
  font-size: 14px;
  margin-bottom: 15px;
  text-align: center;
}

.sns_link {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.link:not(:last-child) {
  margin-right: 20px;
}
</style>

<script>
export default {
  props:{
    shareURL: { type: String, default: '' }
  },

  data: function() {
    return {
      cdn: CDN,
    }
  },

  mounted: async function() {
  },

  methods: {
    getURL: function() {
      return this.shareURL == '' ? location.origin + this.$route.path : this.shareURL
    }
  },

  components: {
  }
}
</script>
