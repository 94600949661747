/*titleやdescriptionを更新*/
export function updateMetaHead(title='', desc='') {
	let myTitle = 'STOCKPOINT for MUFG | ポイント運用×RPG'
	let myDesc = 'ポイントをためて本物の株主へ！株式銘柄などの値動きに連動してポイントが増減するポイント運用サービスです。'
	if (title != '' && title != 'トップ') {
		myTitle = `${title} | STOCKPOINT for MUFG | ポイント運用×RPG`
	}
	if (desc != '') {
		myDesc = desc
	}
	document.title = myTitle
	document.querySelector("meta[property='og:title']").setAttribute('content', myTitle)
	document.querySelector("meta[name='description']").setAttribute('content', myDesc)
	document.querySelector("meta[property='og:description']").setAttribute('content', myDesc)
}

