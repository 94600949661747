<template>
  <div class="download">
    <Title2 :imgName="'title_download_heading.webp'" :width="'147'" :height="'26'" :alt="'ダウンロード'" />
    <div class="download_heading">
      最短10秒で今すぐはじめよう
    </div>
    <div class="download_img">
      <img class="img_ss" :src="`${cdn}/img/top/img_download_img.webp`" width="267" height="366" alt="">
      <img class="img_icon" :src="`${cdn}/img/top/img_app_icon.webp`" width="77" height="77" alt="">
    </div>
    <AppStore />
    <div class="download_text">
      ダウンロード後はSNS認証でアカウント登録を行うだけで完了！<br>
      口座開設や本人確認など、わずらわしい情報入力はありません。
    </div>
  </div>
</template>

<style scoped>
.download {
  padding-top: 30px;
}

.download_heading {
  color: var(--col-main);
  text-align: center;
  padding: 0 20px;
  margin-bottom: 25px;
}

.download_img {
  margin-bottom: 30px;
  padding: 0 20px;
  text-align: center;
}

.img_ss {
  margin-left: -8px;
}

.img_icon {
  position: absolute;
  bottom: 0;
  left: 115px;
  right: -115px;
  z-index: 1;
  margin: auto;
}

.appStore_list {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  margin-bottom: 30px;
}

.appIcon:not(:last-child) {
  margin-right: 20px;
}

.download_text {
  padding: 30px 20px 0;
  line-height: 32px;
  padding-bottom: 30px;
}
</style>

<script>
import Title2 from './Title2'
import AppStore from './AppStore'

export default {
  props:{
  },

  data: function() {
    return {
      cdn: CDN
    }
  },

  mounted: async function() {
  },

  methods: {
  },

  components: {
    Title2,
    AppStore
  }
}
</script>
