<template>
  <div>
    <div class="header">
      <router-link :to="{ name: 'Index' }">
        <img :src="`${cdn}/img/top/logo_spm.webp`" width="209" height="15" alt="STOCKPOINT for MUFG">
      </router-link>
    </div>
    <div class="titleArea">
      <Breadcrumb />
      <h1 class="titleArea_title">銘柄ラインアップ</h1>
      <div class="titleArea_subTitle">
        優良・注目企業の株式（国内・米国）や投資信託、暗号資産や金で ポイント運用しよう。
      </div>
    </div>

    <div>
      <div class="jumpArea_heading">
        キャラクターのレベルに応じて<br>解放される銘柄
      </div>
      <div class="jumpArea">
        <a class="jumpArea_link" href="#sodate">
          ソダテ
          <img class="jumpArea_linkIcon" :src="`${cdn}/img/top/icon_arrow_left.webp`" width="7" height="11" alt="">
        </a>
        <a class="jumpArea_link" href="#manabi">
          マナビ
          <img class="jumpArea_linkIcon" :src="`${cdn}/img/top/icon_arrow_left.webp`" width="7" height="11" alt="アイコン">
        </a>
      </div>
    </div>

    <div class="accordion" id="sodate">
      <input class="accordion_checkbox" id="contents01" name="contents01" type="checkbox" checked>
      <label class="accordion_button" for="contents01" tabIndex="0">
        ソダテの銘柄
        <img class="accordion_icon" :src="`${cdn}/img/top/icon_arrow_down.webp`" width="11" height="7" alt="">
      </label>
      <div class="article">
        <div class="thumbnailArea_bg">
          <img class="thumbnailArea_bgImg" :src="`${cdn}/img/top/img_thumbnail_sodate.webp`" width="375" height="280" alt="ソダテ">
        </div>

        <div class="brandsArea" v-if="dispBrandListSodate.length > 0">
          <div v-for="(brand, index) in dispBrandListSodate" :key="index">
            <div class="brandsLevel" v-if="brand.isTitle">{{ brand.title }}</div>
            <div class="list_name" v-else>
              {{ brand.name }}<span class="list_code">（{{ brand.code }}）</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="accordion" id="manabi">
      <input class="accordion_checkbox" id="contents02" name="contents02" type="checkbox" checked>
      <label class="accordion_button" for="contents02" tabIndex="0">
        マナビの銘柄
        <img class="accordion_icon" :src="`${cdn}/img/top/icon_arrow_down.webp`" width="11" height="7" alt="">
      </label>
      <div class="article">
        <div class="thumbnailArea_bg">
          <img class="thumbnailArea_bgImg" :src="`${cdn}/img/top/img_thumbnail_manabi.webp`" width="375" height="280" alt="マナビ">
        </div>

        <div class="brandsArea" v-if="dispBrandListManabi.length > 0">
          <div v-for="(brand, index) in dispBrandListManabi" :key="index">
            <div class="brandsLevel" v-if="brand.isTitle">{{ brand.title }}</div>
            <div class="list_name" v-else>
              {{ brand.name }}<span class="list_code">（{{ brand.code }}）</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="brandsFooter">
      <BorderLink :routeName="'Feature'" :text="'アプリの特長へもどる'" />
    </div>
  </div>
</template>

<style scoped>
.jumpArea_heading {
  font-size: 24px;
  line-height: 36px;
  color: var(--col-main);
  text-align: center;
  padding-bottom: 30px;
}

.jumpArea {
  padding-bottom: 30px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.jumpArea_link {
  width: 160px;
  margin-right: 15px;
  text-align: center;
  padding: 17px;
  color: var(--col-main);
  border: 1px solid var(--col-main);
  border-radius: 25px;
}

.jumpArea_link:last-child {
  margin-right: 0;
}

.jumpArea_linkIcon {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 17px;
  z-index: 1;
  transform: rotateZ(-90deg);
}

.thumbnailArea_bg {
  height: 280px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.thumbnailArea_bgImg {
  max-width: initial;
}

.brandsArea {
  margin: 0 20px;
  border-bottom: 1px solid var(--col-black50);
}

.brandsLevel {
  margin-top: 5px;
  padding: 25px 0 20px;
  border-top: 1px solid var(--col-black50);
  font-size: 18px;
}

.list_name {
  font-size: 14px;
  padding-bottom: 20px;
}

.list_code {
  white-space: nowrap;
  font-size: 14px;
  color: #aaa190;
}

.brandsFooter {
  padding: 10px 20px 0;
}

.accordion {
  margin-bottom: 30px;
}

.accordion .article {
  padding-left: 0;
  padding-right: 0;
}

.accordion_checkbox:checked ~ .article {
  padding-top: 0;
}
</style>

<script>
import BorderLink from '../components/BorderLink'
import BorderButton from '../components/BorderButton'
import Breadcrumb from '../components/Breadcrumb'
import { getList } from '../lib/listModules'

export default {
  props:{
  },

  data: function() {
    return {
      cdn: CDN,
      brandListSodate: [],
      brandListManabi: [],
      dispBrandListSodate: [],
      dispBrandListManabi: []
    }
  },

  mounted: async function() {
    this.brandListSodate = await getList('brandsSodate.json')
    this.brandListManabi = await getList('brandsManabi.json')

    this.dispBrandListSodate = this.getDispBrandList(this.brandListSodate)
    this.dispBrandListManabi = this.getDispBrandList(this.brandListManabi)
  },

  methods: {
    getDispBrandList: function(brandList) {
      let dispBrandList = []

      brandList.forEach((brand, index) => {
        if (index == 0 || brand.level != brandList[index - 1].level) {
          dispBrandList.push({ isTitle: true, title: `レベル${brand.level}`, name: "", code: "" })
          dispBrandList.push({ isTitle: false, title: '', name: brand.name, code: brand.code })
        } else {
          dispBrandList.push({ isTitle: false, title: '', name: brand.name, code: brand.code })
        }
      })

      return dispBrandList
    }
  },

  components: {
    BorderLink,
    BorderButton,
    Breadcrumb
  }
}
</script>
